import React, { useRef } from "react";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import PrimaryButton from "../../../components/CustomButtons/PrimaryButton";

function QRcodeModal(props) {
  const { table } = props;
  let componentRef = useRef();

  return (
    <>
      <div ref={(el) => (componentRef = el)}>
        <QRCodeBody table={table} />
      </div>
      <div align="center">
        <ReactToPrint
          trigger={() => <PrimaryButton text="Print" />}
          content={() => componentRef}
        />
      </div>
    </>
  );
}

export default QRcodeModal;

export const QRCodeBody = (props) => {
  const table = props.table;
  return (
    <>
      <div align="center" className="font-semibold text-lg">
        Table No :{" "}
        <span style={{ color: "orangered" }}>{table.customTableId}</span>
      </div>
      <div align="center" className="py-5">
        <QRCode level="H" size={250} value={table?.qrCodeLink ?? ""} />
      </div>
    </>
  );
};
