import clsx from "clsx";
import React from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { chooseRestaurantProfileImage, useAuth } from "../../../services/auth";

export default function UploadedImagesModal(props) {
  const { merchantProfileData, setUploadImageModalVisible } = props;
  const { getIdToken } = useAuth();
  const authToken = getIdToken();
  const [selectedImageData, setSelectedImageData] = React.useState(undefined);
  const {
    mutate: chooseProfileImageMutation,
    isSuccess: imageSelectedSuccessfully,
    data: profileImageUploadData,
  } = useMutation((data) => chooseRestaurantProfileImage(data));

  function onUploadRestaurantProfileImage() {
    chooseProfileImageMutation({
      params: { imageUrl: selectedImageData.image },
      token: authToken,
    });
  }

  React.useEffect(() => {
    if (imageSelectedSuccessfully) {
      toast.success(profileImageUploadData?.data?.data);
    }
  }, [imageSelectedSuccessfully, profileImageUploadData]);

  return (
    <div class="container">
      <div class="grid grid-cols-3 gap-0 bg-white">
        {merchantProfileData &&
          merchantProfileData?.data?.data?.pics.map((item, index) => {
            return (
              <span
                key={index}
                onClick={() =>
                  setSelectedImageData({ key: index, image: item })
                }
              >
                <img
                  src={item}
                  alt="profile-images"
                  className={clsx(
                    "photo-gallary object-contain h-48 w-full px-1 mx-auto",
                    selectedImageData?.key === index &&
                      "selected-profile-image border-2 border-red-500"
                  )}
                />
              </span>
            );
          })}
      </div>
      <div
        style={{ display: "flex", marginTop: "20px" }}
        className="button-select-model grid grid-cols-3 gap-0"
      >
        <button
          className="images-cancel-button"
          type="primary"
          onClick={() => setUploadImageModalVisible(false)}
        >
          <span>Cancel</span>
        </button>
        <button
          className="images-submit-button"
          type="primary"
          htmltype="submit"
          onClick={() => {
            onUploadRestaurantProfileImage();
            setUploadImageModalVisible(false);
          }}
        >
          <span>Set Profile Photo</span>
        </button>
      </div>
    </div>
  );
}
