import React from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../services/auth";

export default function WithAuth(WrapperComponent) {
  function Authenticate(props) {
    const history = useHistory();
    const { userProfile } = useAuth();

    const handleRedirect = React.useCallback(() => {
      if (!userProfile && !localStorage.getItem("flunkeyAuthToken")) {
        history.push("/");
      }
    }, [history, userProfile]);

    React.useEffect(() => {
      handleRedirect();
    }, [handleRedirect]);

    return userProfile ? <WrapperComponent {...props} /> : "";
  }

  return Authenticate;
}
