import { Table, Tag } from 'antd'
import moment from 'moment'
import React from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { currentTabIdAtom, tabDataAtom } from '../../../../recoils/dinein'
import { invoiceViewAtom } from '../../../../recoils/helperAtoms'
import { filterForDineInTabs } from '../../../../utils'
import { Colors } from '../../../../utils/colors'
import { useAuth } from '../../../../services/auth'
import { useQuery } from 'react-query'
import { getInvoiceByTabId } from '../../../../services/dinein'
import { orderDetailsByIdAtom } from '../../../../recoils/orders'
import { useState } from 'react'

export default function ClosedTabs(props) {
  const { getIdToken } = useAuth()
  const token = getIdToken()

  const { search, filterDate, currentStatus } = props
  const tabs = useRecoilValue(tabDataAtom)
  const [currentTab, setCurrentTab] = useRecoilState(currentTabIdAtom)
  const [status, setStatus] = React.useState()
  const setInvoiceView = useSetRecoilState(invoiceViewAtom)
  const [orderDetailsValues, setOrderDetailsValues] = useRecoilState(
    orderDetailsByIdAtom,
  )

  const [recordTabId, setRecordTabId] = useState('')

  React.useEffect(() => {
    switch (currentStatus) {
      case 'PENDING TABS':
        setStatus('PENDING')
        break
      case 'CLOSED TABS':
        setStatus('COMPLETED')
        break
      default:
        break
    }
  }, [currentStatus])
  const columns = [
    {
      title: 'Tab',
      key: 'tabId',
      dataIndex: 'tabId',
      width: 120,
      fixed: 'left',
    },
    {
      title: 'Date Created',
      key: 'dateCreated',
      dataIndex: 'createdAt',
      width: 170,

      render: (createdAt) => (
        <>{moment.unix(createdAt._seconds).format('DD MMM YYYY : HH:mm')}</>
      ),
    },
    {
      title: 'Table',
      key: 'tabId',
      dataIndex: 'customTableId',
      width: 100,
    },
    {
      title: 'Tab Status',
      key: 'status',
      dataIndex: 'status',
      width: 120,
      render: (status) => (
        <Tag color={status === 'PENDING' ? 'red' : 'green'}>{status}</Tag>
      ),
    },
    {
      title: 'Payment Status',
      key: 'status',
      dataIndex: 'paid',
      width: 120,
      render: (paid) => (
        <Tag
          color={paid ? Colors.success.description : Colors.failed.description}
        >
          {paid ? 'Paid' : 'To be paid'}
        </Tag>
      ),
    },
  ]

  const { refetch: refetchCurrentTab } = useQuery(
    ['recordTabId', recordTabId],
    () => {
      return getInvoiceByTabId({ token: token, tabId: recordTabId })
    },
    {
      onSuccess: (res) => {
        console.log('Fetched completed order Invoice Data successfully')
        const completedOrderData = res ? mapValues(res?.data?.data) : null

        setOrderDetailsValues(completedOrderData)
      },
      enabled: !!recordTabId,
    },
  )

  const mapValues = (val) => {
    let orderDetailValues = []

    val.items.forEach((item) =>
      orderDetailValues.push({
        name: item.itemName,
        nos: item.quantity,
      }),
    )
    val.items = orderDetailValues
    return val
  }

  return (
    <div className="px-8 pt-2">
      <Table
        columns={columns}
        scroll={{ y: '50vh' }}
        dataSource={filterForDineInTabs(tabs, search, filterDate, status)}
        rowClassName={(record) =>
          record === currentTab ? 'bg-gray-100' : 'bg-white'
        }
        className="px-2 rounded-sm shadow-lg py-0 cursor-pointer"
        onRow={(record, _rowIndex) => {
          return {
            onClick: () => {
              setInvoiceView(false)
              setCurrentTab(record)
              setRecordTabId(record?.tabId)
            },
          }
        }}
        pagination={{
          defaultPageSize: 10,
        }}
      />
    </div>
  )
}
