import { Row } from "antd";
import { React, useState } from "react";
import { useQuery } from "react-query";
import PrimaryButton from "../../../components/CustomButtons/PrimaryButton";
import { getAllAgents } from "../../../services/Agents";
import { useAuth } from "../../../services/auth";
import { AgentCard } from "../Agent/agentCard";

export function AssignAgentModal(props) {
  const { getIdToken } = useAuth();
  const token = getIdToken();
  const { data } = useQuery("agents", () => getAllAgents(token), {
    onSuccess: () => {
      console.log("Fetched agents list succesfully");
    },
  });
  const [selectedAgent, setSelectedAgent] = useState();
  const [disabled, setDisabled] = useState(true);
  const onSelect = (agent) => {
    setDisabled(false);
    setSelectedAgent(agent);
  };
  const onAssign = () => {
    props.onAssignAgent(selectedAgent);
  };
  return (
    <>
      <div className="overflow-scroll" style={{ height: "60vh" }}>
        <Row>
          {data?.data?.data?.map((agent) => (
            <AgentCard
              key={agent.agentId}
              agent={agent}
              onSelect={onSelect}
              selectedAgent={selectedAgent}
            />
          ))}
        </Row>
      </div>
      <div className="flex flex-row-reverse">
        <PrimaryButton text="Assign" disabled={disabled} onClick={onAssign} />
      </div>
    </>
  );
}
