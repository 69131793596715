import React from 'react'
import clsx from 'clsx';

export default function NotificationCircle(props) {
    const { value, marginLeft, absolute, hidden } = props;
    return (
        <div
            className={clsx(
                `inline-flex items-center justify-center ml-${marginLeft} h-5 w-5 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full`,
                { ['hidden']: !value },
                { ['absolute']: absolute },
                { ['hidden']: hidden }

            )}
            {...props}
        >
            {value}
        </div>
    )
}
