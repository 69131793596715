import React from "react";
import { Form, Input, Select } from "antd";
import PrimaryButton from "../../../components/CustomButtons/PrimaryButton";

export function AgentFormModal(props) {
  const { onSubmit, handleCancel, agent } = props;
  const { Option } = Select;
  const [disabled, setDisabled] = React.useState(true);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    agent == null ? onSubmit(values) : onSubmit(values, agent.agentId);
    form.resetFields();
    handleCancel();
  };
  const handleChange = (values) => {
    if (values !== agent) setDisabled(false);
    else setDisabled(true);
  };
  return (
    <div>
      <Form
        form={form}
        name="agent_form"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        layout="vertical"
        initialValues={agent}
        onFinish={onFinish}
        onValuesChange={handleChange}
      >
        <Form.Item label="Name" rules={[{ required: true }]} name="agentName">
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="Email"
          rules={[{ required: true, type: "email" }]}
          name="agentEmail"
        >
          <Input placeholder="abc@gmail.com" />
        </Form.Item>
        <Form.Item label="Phone" rules={[{ required: true }]} name="agentPhone">
          <Input placeholder="9389230489" />
        </Form.Item>
        <Form.Item
          label="Status"
          rules={[{ required: true }]}
          name="agentStatus"
        >
          <Select>
            <Option value="ACTIVE">ACTIVE</Option>
            <Option value="INACTIVE">INACTIVE</Option>
            <Option value="ON_LEAVE">ON LEAVE</Option>
          </Select>
        </Form.Item>
        <PrimaryButton disabled={disabled} text="Submit" type="submit" />
      </Form>
    </div>
  );
}
