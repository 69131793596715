import { EditFilled, PlusOutlined } from '@ant-design/icons'
import { Form, Input, Tabs, Checkbox, Row, Col, Image, Carousel } from 'antd'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import SearchHeaderComponent from '../../../components/HeaderComponents/search_header_component'
import CommonModal from '../../../components/Modal'
import {
  chooseRestaurantProfileImage,
  getProfileDetails,
  updateRestaurantProfile,
  useAuth,
} from '../../../services/auth'
import EditProfileModal from './EditProfileModal'
import UploadedImagesModal from './UploadedImagesModal'
import UploadImageModal from './UploadImageModal'
import RoundedButton from '../../../components/CustomButtons/RoundedButton'
import { useRecoilState } from 'recoil'
import { currentUserProfileDataAtom } from '../../../recoils/auth'
import clsx from 'clsx'
import PrimaryButton from '../../../components/CustomButtons/PrimaryButton'
import OutlinedButton from '../../../components/CustomButtons/OutlinedButton'

const { TabPane } = Tabs

export default function RestaurantProfile() {
  const { getIdToken } = useAuth()
  const token = getIdToken()
  const [readOnly, setReadOnly] = React.useState(true)
  const [
    isUploadImageModalVisible,
    setUploadImageModalVisible,
  ] = React.useState(false)

  const [merchantProfileData, setMerchantProfileData] = useRecoilState(
    currentUserProfileDataAtom,
  )

  const [selectedImage, setSelectedImage] = React.useState()
  const [form] = Form.useForm()

  const [dinein, setDinein] = useState(merchantProfileData?.type[0])
  const [takeaway, setTakeaway] = useState(merchantProfileData?.type[1])
  const [delivery, setDelivery] = useState(merchantProfileData?.type[2])

  const { refetch, isLoading: is } = useQuery(
    'fetchProfileDetails',
    () => getProfileDetails(token),
    {
      onSuccess: (res) => {
        console.log('Fetched profile data')
        console.log(res?.data?.data)
        setMerchantProfileData(res?.data?.data)
      },
    },
  )

  const {
    mutate: chooseProfileImageMutation,
    isLoading: loadingProfilePic,
  } = useMutation((data) => chooseRestaurantProfileImage(data), {
    onSuccess: () => {
      console.log('Profile Pic Set completed')
      setSelectedImage()
      refetch()
    },
  })

  const { mutate: updateProfileMutation, isLoading } = useMutation(
    (data) => updateRestaurantProfile(data),
    {
      onSuccess: () => {
        console.log('Restaurant Data Updated')
        setReadOnly(!readOnly)
        refetch()
      },
    },
  )

  function onUploadRestaurantProfileImage() {
    chooseProfileImageMutation({
      params: { imageUrl: selectedImage },
      token: token,
    })
  }

  const saveProfile = (values) => {

    values.type = [dinein || false, takeaway || false, delivery || false]

    const query = {
      params: values,
      token: token,
    }


    updateProfileMutation(query)
  }

  function handleCancel() {
    setUploadImageModalVisible(false)
  }

  return (
    <>
      <div id="restaurant-profile">
        <SearchHeaderComponent title={'Restaurant Profile'} search={false}>
          <div className="flex flex-row-reverse">
            {!readOnly ? (
              <div>
                <OutlinedButton
                  text="Cancel"
                  handleClick={() => {
                    setReadOnly(!readOnly)
                    form.resetFields()
                  }}
                />
                <span className="mx-4"></span>
                <PrimaryButton
                  type="submit"
                  text="Save"
                  disabled={isLoading}
                  onClick={() => form.submit()}
                />
              </div>
            ) : (
              <RoundedButton
                onClick={() => {
                  setReadOnly(!readOnly)
                  refetch()
                }}
              >
                <EditFilled className="pb-1" style={{ color: '#FF5317' }} />
                <div className="inline-flex pl-2 font-bold">Edit Profile</div>
              </RoundedButton>
            )}
          </div>
        </SearchHeaderComponent>
        <Row>
          <Col
            style={{ height: '80vh' }}
            span={12}
            className="p-10 overflow-scroll"
          >
            <Form
              form={form}
              name="new_merchent_form"
              layout="vertical"
              initialValues={merchantProfileData}
              className="form-container"
              onFinish={saveProfile}
            >
              <Form.Item
                label="Restaurant Name"
                name="restaurantName"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Input readOnly={readOnly} size="large" />
              </Form.Item>
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Input readOnly={readOnly} size="large" />
              </Form.Item>
              <Form.Item label="Restaurant Type">
                <Row gutter={16}>
                  <Col span={8}>
                    <Checkbox
                      defaultChecked={merchantProfileData?.type[0] || false}
                      disabled={readOnly}
                      onChange={(e) => {
                        setDinein(e.target.checked)
                      }}
                    >
                      Dine In
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      defaultChecked={merchantProfileData?.type[1] || false}
                      disabled={readOnly}
                      onChange={(e) => {
                        setTakeaway(e.target.checked)
                      }}
                    >
                      Takeaway
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      // defaultChecked={merchantProfileData?.allowDelivery}
                      checked={merchantProfileData?.type[2] || false}
                      disabled={readOnly}
                      onChange={(e) => {
                        setDelivery(e.target.checked)
                      }}
                    >
                      Self Delivery
                    </Checkbox>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label="Phone"
                name="ownerMobile"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Input readOnly={readOnly} size="large" />
              </Form.Item>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Input readOnly={readOnly} size="large" />
              </Form.Item>
              <Form.Item
                label="Neighbourhood"
                name="neighbourhood"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Input readOnly={readOnly} size="large" />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Input readOnly={readOnly} size="large" />
              </Form.Item>
              <Form.Item
                label="Pincode"
                name="pincode"
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input
                  readOnly={readOnly}
                  size="large"
                  placeholder="999680"
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
          </Col>
          <Col
            style={{ height: '80vh' }}
            span={12}
            className="p-10 overflow-scroll"
          >
            <div className="rounded-xl" align="center">
              <img
                className="rounded-xl w-3/4"
                src={
                  merchantProfileData?.imageUrl ??
                  'https://i.ndtvimg.com/i/2015-05/restaurant-food_625x350_71431116318.jpg'
                }
              />
            </div>
            <Row justify="space-between" align="middle">
              <div className="font-bold text-2xl p-5">Gallery</div>
              <RoundedButton onClick={() => setUploadImageModalVisible(true)}>
                <PlusOutlined className="pb-1" style={{ color: '#FF5317' }} />
                <div className="inline-flex pl-2 font-bold">Add Image</div>
              </RoundedButton>
            </Row>

            <div className="overflow-scroll flex">
              {merchantProfileData?.pics?.map((item, index) => {
                return (
                  <img
                    key={index}
                    src={item}
                    onClick={() => {
                      setSelectedImage(item)
                    }}
                    className={clsx('m-2 h-40', {
                      'ring ring-red-400': selectedImage === item,
                    })}
                  />
                )
              })}
            </div>
            <PrimaryButton
              onClick={onUploadRestaurantProfileImage}
              text="Set as Profile Pic"
              disabled={selectedImage === undefined || loadingProfilePic}
            />
          </Col>
        </Row>
      </div>

      {/* Upload Profile Image Modal */}
      <CommonModal
        title="Upload Image"
        isModalOpen={isUploadImageModalVisible}
        handleCancel={handleCancel}
        destroyOnClose={true}
      >
        <UploadImageModal
          setUploadImageModalVisible={setUploadImageModalVisible}
          refetch={refetch}
        />
      </CommonModal>
    </>
  )
}
