import Axios from "axios";
import { axiosInitialParams } from "../../utils";

const baseAPIUrl = process.env.REACT_APP_API_SERVER;
const axios = Axios.create({
  baseURL: baseAPIUrl,
  axiosInitialParams,
});

// Returns All Categories
export const getAllCategories = async (token) => {
  token = await token;
  return axios.get("/merchant/categories", {
    headers: { Authorization: "Bearer " + token },
  });
};

// Use for Add new category
export const addCategory = async ({ params, token }) => {
  token = await token;
  return await axios
    .post("/merchant/addCategory", params, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else {
        console.log("Error", error.message);
      }
    });
};

// Use for update inventory category
export const updateCategory = async ({ params, token }) => {
  token = await token;
  return await axios
    .post("/merchant/updateCategory", params, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else {
        console.log("Error", error.message);
      }
    });
};

// Use for delete category under inventory
export const deleteCategoryItem = async ({ params, token }) => {
  token = await token;
  return await axios
    .post("/merchant/deleteCategory", params, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else {
        console.log("Error", error.message);
      }
    });
};

// Returns All added Items
export const getAllItems = async (token) => {
  token = await token;
  return axios.get("/merchant/items", {
    headers: { Authorization: "Bearer " + token },
  });
};

// Use for add New item under inventory
export const addNewItem = async ({ params, token }) => {
  token = await token;
  return await axios
    .post("/merchant/addItem", params, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else {
        console.log("Error", error.message);
      }
    });
};

// Use for update item under inventory
export const updateInventoryItem = async ({ params, token }) => {
  token = await token;
  return await axios
    .post("/merchant/updateItem", params, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else {
        console.log("Error", error.message);
      }
    });
};

// Use for delete item under inventory
export const deleteInventoryItem = async ({ params, token }) => {
  token = await token;
  return await axios
    .post("/merchant/deleteItem", params, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else {
        console.log("Error", error.message);
      }
    });
};
