import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import PrimaryButton from "../../../../components/CustomButtons/PrimaryButton";
import { useAuth } from "../../../../services/auth";
import { addCategory, updateCategory } from "../../../../services/inventory";
export default function CategoryForm(props) {
  const { handleCancel, refetchCategory, category } = props;
  const { getIdToken } = useAuth();
  const token = getIdToken();
  const [form] = useForm();
  const { mutate: addNewCategory } = useMutation((data) => addCategory(data), {
    onSuccess: (res) => {
      console.log('Added Category Successfully');
      toast.success("You have sucessfully Added New Category");
      refetchCategory();
    },
  });

  const { mutate: updateCategoryMutation } = useMutation(
    (data) => updateCategory(data),
    {
      onSuccess: (res) => {
        console.log('Updated Category Successfully')
        toast.success("You have sucessfully Updated Category");
        refetchCategory();
      },
    }
  );

  const onUpdateCategoryFinish = (values) => {
    const body = {
      params: {
        ckey: category.ckey,
        name: values?.name,
        newRank: category.rank,
        oldRank: category.rank,
      },
      token: token,
    };
    updateCategoryMutation(body);
    form.resetFields();
    handleCancel(false);
  };

  const onAddNewCategoryFinish = (values) => {
    const query = {
      params: {
        category: values.name,
        rank: 1,
      },
      token: token,
    };
    addNewCategory(query);
    form.resetFields();
    handleCancel(false);
  };

  return (
    <>
      <Form
        form={form}
        name="new_category_form"
        layout="vertical"
        initialValues={category}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        requiredMark={false}
        onFinish={
          category === undefined
            ? onAddNewCategoryFinish
            : onUpdateCategoryFinish
        }
      >
        <Form.Item
          label="Category Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Required",
            },
          ]}
        >
          <Input size="large" placeholder="Enter Category Name" />
        </Form.Item>
        <PrimaryButton text="Submit" type="submit" />
      </Form>
    </>
  );
}
