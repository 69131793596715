import React from "react";

function OutlinedButton(props) {
  return (
    <button
      className="border rounded-md border-black text-sm tracking-wide px-4 py-1"
      onClick={props.handleClick}
    >
      {props.text}
    </button>
  );
}

export default OutlinedButton;
