import React from "react";
import { Form, Input, Select } from "antd";
import PrimaryButton from "../../../components/CustomButtons/PrimaryButton";

export function TableFormModal(props) {
  const { onSubmit, handleCancel, table } = props;
  const { Option } = Select;
  const [disabled, setDisabled] = React.useState(true);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    table === undefined ? onSubmit(values) : onSubmit(values, table.tableId);
    form.resetFields();
    handleCancel();
  };
  const handleChange = (values) => {
    if (values !== table) setDisabled(false);
    else setDisabled(true);
  };
  return (
    <div>
      <Form
        form={form}
        name="table_form"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        layout="vertical"
        initialValues={table}
        onFinish={onFinish}
        onValuesChange={handleChange}
      >
        <Form.Item
          label="Table Name"
          rules={[{ required: true }]}
          name="customTableId"
        >
          <Input placeholder="Table Name" />
        </Form.Item>
        <Form.Item label="Type" rules={[{ required: true }]} name="type">
          <Input placeholder="Family" />
        </Form.Item>
        <Form.Item label="Seats" rules={[{ required: true }]} name="noOfSeats">
          <Input placeholder="2" />
        </Form.Item>
        <Form.Item label="Status" rules={[{ required: true }]} name="status">
          <Select>
            <Option value="AVAILABLE">AVAILABLE</Option>
            <Option value="UNAVAILABLE">UNAVAILABLE</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Description"
          rules={[{ required: true }]}
          name="description"
        >
          <Input placeholder="Description" />
        </Form.Item>
        <PrimaryButton disabled={disabled} text="Submit" type="submit" />
      </Form>
    </div>
  );
}
