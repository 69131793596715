/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { useHistory } from "react-router-dom";

export default function (WrapperComponent) {
  function Authenticate(props) {
    const history = useHistory();
    const authSelector = true;
    const { isAuthenticated } = authSelector;

    const handleRedirect = React.useCallback(() => {
      if (!isAuthenticated && !localStorage.getItem("flunkeyAuthToken")) {
        history.push("/admin/login");
      }
    }, [history, isAuthenticated]);

    React.useEffect(() => {
      handleRedirect();
    }, [handleRedirect]);

    return isAuthenticated ? <WrapperComponent {...props} /> : "";
  }

  return Authenticate;
}
