import { Checkbox, Form, Input } from "antd";
import React from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { updateRestaurantProfile, useAuth } from "../../../services/auth";

export default function EditProfileModal(props) {
  const { setEditProfileModalVisible, merchantProfileData } = props;
  const { getIdToken } = useAuth();
  const token = getIdToken();
  const {
    mutate: updateProfileMutation,
    data: updatedProfileData,
    isLoading: updatingProfile,
    isSuccess: updatedSuccessfully,
  } = useMutation((data) => updateRestaurantProfile(data));

  const onUpdateProfileFinish = (values) => {
    const query = {
      params: values,
      token: token,
    };
    updateProfileMutation(query);
    setEditProfileModalVisible(false);
  };

  React.useEffect(() => {
    if (
      updatedSuccessfully &&
      updatedProfileData?.statusCode === 200 &&
      updatedProfileData?.status === "success"
    ) {
      toast.success("You have sucessfully Updated Profile");
    }
    if (updatedSuccessfully && updatedProfileData?.statusCode === 500) {
      toast.warning(updatedProfileData?.message);
    }
  }, [updatedSuccessfully, updatedProfileData]);

  return (
    <>
      <Form
        name="update_profile_form"
        layout="vertical"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        requiredMark={false}
        onFinish={onUpdateProfileFinish}
        className="edit-profile-form"
        initialValues={
          {
            'restaurantName': merchantProfileData.restaurantName ?? "",
            'description': merchantProfileData.description ?? "",
            'type': merchantProfileData.type ?? [],
            'ownerMobile': merchantProfileData.ownerMobile ?? "",
            'address': merchantProfileData.address ?? "",
            'city': merchantProfileData.city ?? "",
            'state': merchantProfileData.state ?? "",
          }
        }
      >
        <Form.Item
          label="Restaurant Name"
          name="restaurantName"
        >
          <Input
            size="large"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description">
          <Input
            size="large"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="type"
          label="Restaurant Type"
        >
          <Checkbox.Group>
            <Checkbox
              className="pr-2"
              size="large"
              label="Dine In"
              value="dine_in"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            />
            Dine In
            <Checkbox
              className="pr-2 pl-4"
              size="large"
              label="Seat In"
              value="seat_in"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            />
            Seat In
          </Checkbox.Group >
        </Form.Item>
        <Form.Item
          name="ownerMobile"
          label="Phone">
          <Input
            size="large"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address">
          <Input
            size="large"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="city"
          label="City">
          <Input
            size="large"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="state"
          label="State">
          <Input
            size="large"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          />
        </Form.Item>
        <button
          className="category-submit-button"
          type="primary"
          htmltype="submit"
        >
          <span>{updatingProfile ? "Loading..." : "Update"}</span>
        </button>
      </Form>
    </>
  );
}
