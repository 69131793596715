import { Checkbox, Col, Descriptions, Row, Table, Tag } from "antd";
import clsx from "clsx";
// import moment from "moment";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import {
  currentTakeawayStatusTabAtom,
  takeAwayDataAtom,
  takeAwayDetailsByIdAtom,
} from "../../../recoils/takeaway";
import { useAuth } from "../../../services/auth";
import {
  getAllTakeAway,
  getInvoiceByOrderId,
  updateTakeAwayOrderStatus,
} from "../../../services/takeaway";
import InvoiceDetails from "./InvoiceDetails";
import { useReactToPrint } from "react-to-print";
import OrderDetailsItem from "../../../components/OrderDetailsCard/order_details_item";
import OrderDetailsCard from "../../../components/OrderDetailsCard/order_details_card";
import PrintDetails from "./printDetails";
import { Colors } from "../../../utils/colors";
import OutlinedButton from "../../../components/CustomButtons/OutlinedButton";
import PrimaryButton from "../../../components/CustomButtons/PrimaryButton";
import { invoiceViewAtom } from "../../../recoils/helperAtoms";

export default function TakeAwayOrderDetails() {
  const { getIdToken } = useAuth();
  const token = getIdToken();

  const [takeAwayDetailsValues, setTakeAwayDetailsById] = useRecoilState(
    takeAwayDetailsByIdAtom
  );
  const [, setTakeAwayData] = useRecoilState(takeAwayDataAtom);
  const [, setCurrentFoodStatusTab] = useRecoilState(
    currentTakeawayStatusTabAtom
  );
  const ref = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const [invoiceView, setInvoiceView] = useRecoilState(invoiceViewAtom);

  const [eta, setEta] = useState(15);
  const {
    mutate: getInvoiceDetailsMutation,
    data: orderInvoiceData,
    // isSuccess: isFetchedInvoiceDetailsSuccessfully,
  } = useMutation((data) => getInvoiceByOrderId(data));

  const {
    data: updatedTakeAwayData,
    isSuccess: fetchedAllTakeAway,
    refetch: refetchTakeAway,
  } = useQuery("fetchAllTakeAwayData", () => getAllTakeAway(token));

  const {
    mutate: updateTakeAwayOrderMutation,
    data: updatedTakeAwayOrderStatusData,
    isSuccess: updatedStatusSucessfully,
    isLoading,
  } = useMutation((data) => updateTakeAwayOrderStatus(data));

  React.useEffect(() => {
    if (updatedTakeAwayOrderStatusData && updatedStatusSucessfully) {
      toast.success(
        "Order is " +
          updatedTakeAwayOrderStatusData?.data?.data?.status.toLowerCase()
      );
    }
    if (updatedStatusSucessfully) {
      setTakeAwayDetailsById(updatedTakeAwayOrderStatusData?.data?.data);
      refetchTakeAway();
      if (fetchedAllTakeAway) setTakeAwayData(updatedTakeAwayData?.data?.data);
      setCurrentFoodStatusTab(getNextStatus(takeAwayDetailsValues?.status));
    }
  }, [updatedTakeAwayOrderStatusData, updatedStatusSucessfully]);

  // function print() {
  //   var content = document.getElementById("invoice");
  //   var pri = document.getElementById("ifmcontentstoprint").contentWindow;
  //   pri.document.open();
  //   pri.document.write(content.innerHTML);
  //   pri.document.close();
  //   pri.focus();
  //   pri.print();
  // }

  const getPrimaryButtonText = (status) => {
    switch (status) {
      case "PENDING":
        return "Accept & Prepare";
      case "PREPARING":
        return "Move To Prepared";
      case "PREPARED":
        return "Move To Completed";
      case "OUT_FOR_DELIVERY":
        return "COMPLETED";
      default:
        return undefined;
    }
  };

  const handleViewInvoice = () => {
    getInvoiceDetailsMutation({
      orderId: takeAwayDetailsValues?.orderId,
      token: token,
    });
    setInvoiceView(!invoiceView);
  };

  const handleDecline = () => {
    updateTakeAwayOrderMutation({
      token: token,
      tokenId: takeAwayDetailsValues?.orderId,
      body: {
        status: "MERCHANT_DECLINED",
        eta: 0,
      },
    });
  };

  const handleContinue = () => {
    const nextStatus = getNextStatus(takeAwayDetailsValues?.status);
    const body = {
      status: nextStatus,
      eta: eta,
    };
    updateTakeAwayOrderMutation({
      token: token,
      itemId: takeAwayDetailsValues?.orderId,
      params: body,
    });
  };

  function getNextStatus(status) {
    switch (status) {
      case "PENDING":
        return "PREPARING";
      case "PREPARING":
        return "PREPARED";
      case "PREPARED":
        return "COMPLETED";
      case "COMPLETED":
        return undefined;
      default:
        return undefined;
    }
  }

  function getStatus(status) {
    var base =
      "inline-flex items-center  border border-transparent text-s font-medium rounded px-2 py-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ";
    switch (status) {
      case "PENDING":
        return base + "bg-blue-100";
      case "PREPARING":
        return base + "bg-yellow-200";
      case "PREPARED":
        return base + "bg-green-200";
      case "COMPLETED":
        return base + "bg-red-100";
      default:
        return "";
    }
  }
  const columns = [
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Quantity",
      dataIndex: "nos",
      key: "nos",
      align: "center",
    },
    {
      title: "Availability",
      dataIndex: "nos",
      key: "nos",
      align: "center",
      render: () => <Checkbox checked />,
    },
  ];

  return (
    <>
      {!invoiceView ? (
        <OrderDetailsCard>
          <Descriptions
            bordered
            className="flex-none"
            labelStyle={{ backgroundColor: "white", color: "grey" }}
            title={
              <div className="px-5 pt-5 text-lg leading-6">Order Details</div>
            }
            size="small"
            column={1}
          >
            <Descriptions.Item label="Order Id">
              {takeAwayDetailsValues?.orderId
                ? takeAwayDetailsValues.orderId
                : "To be selected"}
            </Descriptions.Item>
            <Descriptions.Item label="Note">
              <Tag color={"yellow"}>
              {takeAwayDetailsValues?.note
                ? takeAwayDetailsValues.note
                : "No Note"}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Phone">
              {takeAwayDetailsValues?.userPhonenumber
                ? takeAwayDetailsValues.userPhonenumber
                : "000-000-0000"}
            </Descriptions.Item>
            <Descriptions.Item label="Amount">
              {takeAwayDetailsValues?.total
                ? takeAwayDetailsValues?.total.toFixed(2)
                : "To be decided"}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              <Tag
                color={
                  takeAwayDetailsValues?.status === "PENDING"
                    ? "red"
                    : takeAwayDetailsValues?.status === "PREPARING"
                    ? "gold"
                    : takeAwayDetailsValues?.status === "PREPARED"
                    ? "magenta"
                    : takeAwayDetailsValues?.status === "COMPLETED"
                    ? "green"
                    : ""
                }
              >
                {takeAwayDetailsValues?.status
                  ? takeAwayDetailsValues?.status?.replaceAll("_", " ")
                  : "To be decided"}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Serve/Pack">
              <Tag color={takeAwayDetailsValues?.serveOnSite ? "cyan" : "blue"}>
                {takeAwayDetailsValues?.serveOnSite ? "Serve" : "Pack"}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Payment Status">
              {
                <Tag
                  color={
                    takeAwayDetailsValues?.paid
                      ? Colors.success.description
                      : Colors.failed.description
                  }
                >
                  {takeAwayDetailsValues?.paid ? "Paid" : "To be paid"}
                </Tag>
              }
            </Descriptions.Item>
          </Descriptions>
          <Table
            size="small"
            columns={columns}
            className="px-2 ant-table-thead flex-grow "
            pagination={{ position: ["none"] }}
            title={() => (
              <span className="text-lg px-5 font-medium">Items</span>
            )}
            // scroll={{ y: "calc(100vh - 300px)" }}
            dataSource={takeAwayDetailsValues?.items}
          />

          {takeAwayDetailsValues?.status === "PENDING" && (
            <Row justify="space-between" align="middle" className="px-5 mt-2">
              <Col className="text-gray-700 text-lg font-semibold">ETA</Col>
              <Row>
                <button
                  onClick={() => setEta(eta - 5)}
                  class=" bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-400 w-10 rounded-l cursor-pointer outline-none"
                >
                  <span class="m-auto text-2xl font-thin">−</span>
                </button>
                <input
                  type="number"
                  class=" text-center border-white w-16  bg-gray-100 font-semibold text-md text-gray-700"
                  onChange={(e) => setEta(Number(e.target.value))}
                  value={eta}
                  autofocus
                ></input>
                <button
                  onClick={() => setEta(eta + 5)}
                  class="bg-gray-200 text-gray-600 hover:text-gray-700 hover:bg-gray-400 w-10 rounded-r cursor-pointer"
                >
                  <span class="m-auto text-2xl font-thin">+</span>
                </button>
              </Row>
            </Row>
          )}

          <Row justify="center" gutter={[24, 12]} className="px-5 my-4">
            <Col>
              <OutlinedButton
                handleClick={
                  takeAwayDetailsValues?.status !== "PENDING"
                    ? handleViewInvoice
                    : handleDecline
                }
                text={
                  takeAwayDetailsValues?.status !== "PENDING"
                    ? "View Invoice"
                    : "Decline"
                }
              />
            </Col>
            {getNextStatus(takeAwayDetailsValues?.status) ? (
              <Col>
                <PrimaryButton
                  disabled={isLoading}
                  text={getPrimaryButtonText(takeAwayDetailsValues?.status)}
                  onClick={handleContinue}
                />
              </Col>
            ) : null}
          </Row>
        </OrderDetailsCard>
      ) : (
        <div className="px-3 shadow-200" id="invoice">
          <div className="order-details-buttons bg-gray-100 shadow-lg">
            <InvoiceDetails
              orderInvoiceData={orderInvoiceData}
              setInvoiceView={setInvoiceView}
            />
          </div>
        </div>
      )}
    </>
  );
}
