import Axios from "axios";
import { axiosInitialParams } from "../../utils";

const baseAPIUrl = process.env.REACT_APP_API_SERVER;
const axios = Axios.create({
  baseURL: baseAPIUrl,
  axiosInitialParams,
});

// return all takeaway details
export const getAllTakeAway = async (token) => {
  token = await token;
  return axios.get("/merchant/takeAway", {
    headers: { Authorization: "Bearer " + token },
  });
};
// returns details of takeAway by takeAwayId
export const getTakeAwayDetailsById = async ({ takeAwayId, token }) => {
  token = await token;
  try {
    return await axios.get(`/merchant/takeAway/${takeAwayId}`, {
      headers: { Authorization: "Bearer " + token },
    });
  } catch (error) {
    return error;
  }
};

// posts takeaway detail
export const updateTakeAwayStatus = async (id, params) => {
  try {
    return await axios
      .post(`takeAway/merchant/updateStatus/${id}`, params)
      .then((response) => response)
      .catch((error) => error.response);
  } catch (error) {
    return error;
  }
};

// Get tab invoice status
export const getInvoiceByOrderId = async ({ token, orderId }) => {
  token = await token;
  try {
    return await axios
      .get(`merchant/takeAway/invoice/${orderId}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => response)
      .catch((error) => error.response);
  } catch (error) {
    return error;
  }
};

// Update Take Away status
export const updateTakeAwayOrderStatus = async ({ token, itemId, params }) => {
  token = await token;
  try {
    return await axios
      .post(`merchant/takeAway/updateStatus/${itemId}`, params, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => response)
      .catch((error) => error.response);
  } catch (error) {
    return error;
  }
};
