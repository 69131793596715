import Axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'
import { currentUserProfileDataAtom } from '../../recoils/auth'
import { axiosInitialParams } from '../../utils'
import { auth, googleProvider } from '../firebase'

const baseAPIUrl = process.env.REACT_APP_API_SERVER
const axios = Axios.create({
  baseURL: baseAPIUrl,
  axiosInitialParams,
})

const AuthContext = React.createContext()

export function useAuth() {
  return React.useContext(AuthContext)
}

//firebase auth provider
export function AuthProvider({ children }) {
  const [loading, setLoading] = React.useState(true)
  const [userProfile, setProfileData] = useRecoilState(
    currentUserProfileDataAtom,
  )
  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function signInWithGoogle() {
    return auth.signInWithPopup(googleProvider)
  }

  async function signOut() {
    return await auth.signOut()
  }

  async function getIdToken() {
    return await auth.currentUser?.getIdToken()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return userProfile.updateEmail(email)
  }

  function updatePassword(password) {
    return userProfile.updatePassword(password)
  }

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setProfileData(user)
      setLoading(false)
    })
    return unsubscribe
  }, [setProfileData])

  const value = {
    userProfile,
    signInWithGoogle,
    signup,
    signOut,
    getIdToken,
    resetPassword,
    updateEmail,
    updatePassword,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

//use for merchant register/signup request
export const requestUserRegister = async ({ params, token }) => {
  try {
    token = await token
    const { data, status } = await axios
      .post('/merchantSignup', params, {
        headers: { Authorization: 'Bearer ' + token },
      })
      .then((res) => res)
      .catch((error) => error.response)
    if (status === 200 && data?.token) {
      toast.success(data?.message)
      return data
    }
  } catch (error) {
    toast.error(error?.message)
  }
}

// returns merchant profile details
export const getProfileDetails = async (token) => {
  token = await token
  return axios.get('/merchant/profile', {
    headers: { Authorization: 'Bearer ' + token },
  })
}
// update Restaurant Status
export const updateRestaurantStatus = async ({ params, token }) => {
  token = await token
  return axios.post('/merchant/updateRestaurantStatus', params, {
    headers: { Authorization: 'Bearer ' + token },
  })
}
// Claim Restaurant
export const claimRestaurant = async ({ params, token }) => {
  token = await token
  return axios.post('/claimRestaurant', params, {
    headers: { Authorization: 'Bearer ' + token },
  })
}
// returns merchant notification details
export const getNotificationDetails = async (token) => {
  token = await token
  return axios.get('/merchant/notifications', {
    headers: { Authorization: 'Bearer ' + token },
  })
}
// update Restaurant Profile
export const updateRestaurantProfile = async ({ params, token }) => {
  token = await token
  return axios.put('/merchant/profile', params, {
    headers: { Authorization: 'Bearer ' + token },
  })
}
// Upload Restaurant Image
export const addRestaurantProfileImage = async ({ params, token }) => {
  token = await token
  return axios.post('/merchant/addProfilePics', params, {
    headers: { Authorization: 'Bearer ' + token },
  })
}
// Choose Restaurant Image
export const chooseRestaurantProfileImage = async ({ params, token }) => {
  token = await token
  return axios.post('/merchant/chooseProfilePic', params, {
    headers: { Authorization: 'Bearer ' + token },
  })
}
