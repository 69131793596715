import { DeleteFilled } from '@ant-design/icons'
import { Col, Popconfirm, Row, Table } from 'antd'
import moment from 'moment'
import React from 'react'
import { useAuth } from '../../../services/auth'
import clsx from 'clsx'
import PrimaryButton from '../../../components/CustomButtons/PrimaryButton'
import ReactToPrint from 'react-to-print'
import OutlinedButton from '../../../components/CustomButtons/OutlinedButton'
import { PrintKOTDetails } from './PrintDetails'

const KOTDetails = (props) => {
  const { getIdToken } = useAuth()
  const token = getIdToken()
  let ref = React.useRef()

  var items = props.orderInvoiceData?.items

  let totalQty = 0

  if (items != null) {
    items.map((item) => {
      totalQty += item.nos
    })
  }

  const columns = [
    {
      title: 'Sn',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: 'Qty',
      dataIndex: 'nos',
      key: 'nos',
      width: 40,
    },
  ]

  if (props.currentTab?.tabOpen) {
    columns.push({
      title: '',
      key: 'delete',
      render: (item) => {
        return (
          <Popconfirm
            title="Are you sure to perform this actions?"
            onConfirm={() =>
              props.removeItemFromInvoiceMutation({
                token: token,
                params: {
                  itemId: item.itemId,
                  orderId: item.orderId,
                },
              })
            }
            okText="Delete"
          ></Popconfirm>
        )
      },
      width: 30,
    })
  }
  return (
    <div className="p-5 text-center flex flex-col" style={{ height: '90vh' }}>
      <div className="flex-none">
        <div className="p-1">
          <h2 className="text-xl font-bold">
            {props.currentTab?.customTableId}
          </h2>
          <div className="text-md">
            {props.orderInvoiceData?.restaurantName}
          </div>
        </div>

        <hr style={{ borderTop: '1px dashed' }} />

        <Row justify="space-between" className="p-2">
          <Col>Order Id : {props.orderInvoiceData?.orderId}</Col>
          <Col>Tab Id : {props.currentTab?.tabId}</Col>
          <Col>
            {moment(Date.parse(props.orderInvoiceData?.createdAt)).format(
              'DD MMMM YYYY hh:mm',
            )}
          </Col>
        </Row>

        <hr style={{ borderTop: '1px dashed' }} />
      </div>

      <div className="p-1 flex-grow">
        <Table
          size="small"
          scroll={{ y: `calc(100vh - 500px)` }}
          pagination={{ position: ['none'], pageSize: items.length }}
          columns={columns}
          dataSource={items}
          className="table-grey"
        />
        <hr style={{ borderTop: '1px dashed' }} />
        <Row justify="space-between">
          <Col className="text-left text-gray-600 font-medium">
            <div className="py-2">Note:</div>
          </Col>
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">{props.orderInvoiceData?.note}</div>
          </Col>
        </Row>
        <hr style={{ borderTop: '1px dashed' }} />
        <Row justify="space-between">
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">Total Qty: </div>
          </Col>
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">{totalQty}</div>
          </Col>
        </Row>
      </div>
      <div className="flex-none">
        <Row justify="center" gutter={[24, 12]} className="px-3 my-2">
          <Col>
            <OutlinedButton
              handleClick={() => props.setInvoiceView(false)}
              text="Back"
            />
          </Col>

          <Col>
            <ReactToPrint
              trigger={() => <PrimaryButton text="Print" />}
              content={() => ref}
            />
          </Col>
        </Row>
      </div>

      <div className="hidden">
        <div ref={(el) => (ref = el)}>
          <PrintKOTDetails
            orderInvoiceData={props.orderInvoiceData}
            tabId={props.currentTab?.tabId}
            totalQty={totalQty}
          />
        </div>
      </div>
    </div>
  )
}
export default KOTDetails
