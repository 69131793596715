import React from "react";

function DeclineButton(props) {
  return (
    <button
      className="bg-red-500 px-8 py-2 my-2 rounded-md text-white text-sm tracking-wide"
      onClick={props.handleDecline}
    >
      {props.text}
    </button>
  );
}

export default DeclineButton;
