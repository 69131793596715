import { Avatar } from "antd";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import NotificationIcon from "../../assets/images/notification-icon.png";
import UserIcon from "../../assets/images/user-icon.png";
import { useAuth } from "../../services/auth";
import CommonModal from "../Modal";
import LogOutModal from "./LogOutModal";
import NotificationModal from "./NotificationModal";
import { LogoutOutlined, BellOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

export default function AdminHeader(props) {
  const history = useHistory();
  const { signOut } = useAuth();
  const { getIdToken } = useAuth();
  const { fetchedNotificationData, notificationData } = props;
  const status = ["All", "Orders", "Reservations"];
  const [isLogoutModalVisible, setLogoutModalVisible] = React.useState(false);
  const [isNotificationModalVisible, setNotificationModalVisible] =
    React.useState(false);

  async function handleMenuClick() {
    await signOut();
    localStorage.removeItem("flunkeyAuthToken");
    localStorage.removeItem("flunkeyMerchantStore");
    history.push("/");
  }

  function handleCancel() {
    setLogoutModalVisible(false);
  }

  const orderTag = <div className="order-tag-name">Takeaway</div>;

  return (
    <>
      <div className="admin-avatar">
        <Avatar
          style={{ backgroundColor: "#fcfcfc", cursor: "pointer" }}
          onClick={() => setLogoutModalVisible(true)}
          icon={<LogoutOutlined style={{ color: "#000" }} />}
        ></Avatar>
      </div>

      {/* LogOut Modal */}
      <CommonModal
        isModalOpen={isLogoutModalVisible}
        handleCancel={handleCancel}
        modalWidth={320}
      >
        <LogOutModal
          setLogoutModalVisible={setLogoutModalVisible}
          handleMenuClick={handleMenuClick}
        />
      </CommonModal>

      {/* Notification Modal */}
      {isNotificationModalVisible && (
        <NotificationModal
          setNotificationModalVisible={setNotificationModalVisible}
        >
          <div className="">
            <div className="notification-title">
              Notifications ({notificationData && notificationData?.length})
            </div>
            <div className="notification-status-badge">
              {status.map((name, index) => {
                return (
                  <div key={index} className="notification-status-name">
                    {name}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="notification-list">
            {fetchedNotificationData &&
              notificationData?.map((item, index) => {
                return (
                  <div key={index} className="notification-list-item">
                    <div className="notification-list-item-name">
                      {item.type === "takeaway" ? (
                        <span style={{ display: "flex" }}>
                          New Order #{item?.orderId} -{" "}
                          <div className="order-tag-name">Take away</div>
                        </span>
                      ) : item.type === "live" ? (
                        <span style={{ display: "flex" }}>
                          New Order #${item?.orderId} -
                          <div className="order-tag-name"> Dine In </div>
                        </span>
                      ) : (
                        `New Reservation`
                      )}
                    </div>
                    <div
                      style={{
                        lineHeight: "40px",
                        paddingRight: "10px",
                        color: "#878787",
                        fontSize: "12px",
                      }}
                    >
                      {moment(item?.createdAt).format("m")}m
                    </div>
                  </div>
                );
              })}
          </div>
        </NotificationModal>
      )}
    </>
  );
}
