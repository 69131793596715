import { DownOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/svgs/logo.svg";
import Anshul from "../../assets/images/team/anshul.jpeg";
import Twinkle from "../../assets/images/team/Twinkle.jpeg";
import bharath from "../../assets/images/team/bharath.jpeg";
import Nickil from "../../assets/images/team/Nickil.jpg";
import Sachin from "../../assets/images/team/Sachin.jpeg";
import Shakeb from "../../assets/images/team/Shakeb.jpeg";
import Jassi from "../../assets/images/team/Jassi.jpeg";
import faisal from "../../assets/images/team/faisal.jpeg";

export default function AboutUs() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <section className="p-20 pt-40">
        <div
          className="text-4xl font-semibold text-center tracking-wider"
          style={{ lineHeight: "50px" }}
        >
          Our Vision is to{" "}
          <span className="gradient-reverse bg-clip-text">empower</span> the
          <br />
          <span className="gradient-reverse bg-clip-text">F&B industry </span>
          with <span className="gradient-reverse bg-clip-text">technology</span>
          .
        </div>
        <div
          className="text-xs text-center tracking-widest font-medium leading-10"
          style={{ color: "#3F4C6B" }}
        >
          Become our partner in your digital transformation journey at zero
          cost.
        </div>
      </section>
      <section>
        <div className="bg-black flex justify-center items-center relative">
          <img
            className="w-full object-cover opacity-70"
            style={{ height: "320px" }}
            src="https://images.unsplash.com/photo-1504654613223-1eccc5820922?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
          />
          <Logo className="icon-color-white absolute" />
        </div>
      </section>
      <section className="p-20">
        <div
          className="text-3xl font-semibold text-center tracking-wider"
          style={{ lineHeight: "50px" }}
        >
          Our Story
        </div>
        <div
          className="text-sm text-justify tracking-widest justify-center m-auto font-medium leading-6 pt-6"
          style={{ color: "#3F4C6B", maxWidth: "720px" }}
        >
          <p>
            Every day millions of people go to the restaurant and have a meal in
            the usual non-digitized way. This effort requires resources and adds
            cost to the business of running restaurants. To reduce this and
            other kinds of redundant work, a team of young and experienced
            entrepreneurs came together to transform restaurants, cafes, bars,
            and all different types of F&B stores into digitizing operations and
            saving money without investing money.
          </p>
          <p className="py-5">
            Our goal has been simple from day one to create a digital Flunkey
            which is customizable as per the needs of our partner stores and
            empower them to adopt digital solutions at scale and leave the
            redundant work to Flunkey that too at a minimal cost.
          </p>
          <p>
            But unlike others, we did not ignore the customer's needs and their
            pain points in all this. We have designed our platform and app so
            that it appeals to the users and is very simple and secure.
          </p>
          <p className="py-5 gradient-reverse bg-clip-text">
            Welcome, Flunkey, into your lives!!
          </p>
        </div>
      </section>
      <section className="p-20" style={{ background: "#F9F9F9" }}>
        <Row justify="end">
          <Col lg={8} md={24}>
            <div
              className="text-3xl font-semibold text-left tracking-wider"
              style={{ lineHeight: "50px" }}
            >
              The Team
            </div>
            <div
              className="text-xs text-left tracking-widest font-medium leading-6"
              style={{ color: "#3F4C6B" }}
            >
              This team ensures that the restaurants focus on their customers
              and their food.
            </div>
          </Col>
          <Col
            lg={16}
            md={24}
            className="flex pt-6 lg:p-0 justify-start lg:justify-end"
          >
            <div className="items-between flex flex-col">
              <div className="flex justify-between">
                <div>
                  <img
                    style={{
                      height: "190px",
                      width: "190px",
                      filter: "grayscale(100%)",
                    }}
                    className="rounded-xl m-4 object-cover"
                    src={Anshul}
                  />
                  <div className="text-lg pl-8 font-semibold text-left tracking-wider">
                    Anshul Kataria
                  </div>
                  <div className="text-xs pl-8 text-left tracking-widest font-medium leading-6">
                    Co-Founder and CEO
                  </div>
                </div>
                <div>
                  <img
                    style={{
                      height: "190px",
                      width: "190px",
                      filter: "grayscale(100%)",
                    }}
                    className="rounded-xl m-4 object-cover"
                    src={Sachin}
                  />
                  <div className="text-lg pl-8 font-semibold text-left tracking-wider">
                    Sachin Naag
                  </div>
                  <div className="text-xs pl-8 text-left tracking-widest font-medium leading-6">
                    Co-Founder and CPO
                  </div>
                </div>

                <div>
                  <img
                    style={{
                      height: "190px",
                      width: "190px",
                      filter: "grayscale(100%)",
                    }}
                    className="rounded-xl m-4 object-cover"
                    src={Twinkle}
                  />
                  <div className="text-lg pl-8 font-semibold text-left tracking-wider">
                  Twinkle Tiwari
                  </div>
                  <div className="text-xs pl-8 text-left tracking-widest font-medium leading-6">
                    Co-Founder and COO
                  </div>
                </div>
              </div>
              <div className="flex">
                <div>
                  <img
                    style={{
                      height: "190px",
                      width: "190px",
                      filter: "grayscale(100%)",
                    }}
                    className="rounded-xl m-4 object-cover"
                    src={bharath}
                  />
                  <div className="text-md pl-8 font-semibold text-left tracking-wider">
                    Bharath
                  </div>
                  <div className="text-xs pl-8 text-left tracking-widest font-medium leading-6">
                    Founding Member, and Tech Lead
                  </div>
                </div>
                <div>
                  <img
                    style={{
                      height: "190px",
                      width: "190px",
                      filter: "grayscale(100%)",
                    }}
                    className="rounded-xl m-4 object-cover"
                    src={Jassi}
                  />
                  <div className="text-md pl-8 font-semibold text-left tracking-wider">
                    Jaswinder Singh
                  </div>
                  <div className="text-xs pl-8 text-left tracking-widest font-medium leading-6">
                    Founding Member, and Backend
                  </div>
                </div>
                <div>
                  <img
                    style={{
                      height: "190px",
                      width: "190px",
                      filter: "grayscale(100%)",
                    }}
                    className="rounded-xl m-4 object-cover"
                    src={faisal}
                  />
                  <div className="text-md pl-8 font-semibold text-left tracking-wider">
                    Faisal Javed
                  </div>
                  <div className="text-xs pl-8 text-left tracking-widest font-medium leading-6">
                    Application and Frontend
                  </div>
                </div>
              </div>
              {/* <button className="gradient-reverse bg-clip-text text-lg pt-16">
              See Full Team <DownOutlined style={{ color: "#FF5317" }} />
            </button> */}
            </div>
          </Col>
        </Row>
      </section>
      <section className="p-20" style={{ background: "#F4F0E9" }}>
        <div
          className="text-3xl font-semibold text-left tracking-wider"
          style={{ lineHeight: "50px" }}
        >
          Advisors & Experts
        </div>
        {/* <div
          className="text-xs text-left tracking-widest font-medium leading-6"
          style={{ color: "#3F4C6B" }}
        >
          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
          sint. Velit officia consequat duis.
        </div> */}
        <div className="flex justify-center items-center">
          <div
            style={{ width: "380px", height: "650px" }}
            className="rounded-xl bg-white mx-5 mt-20"
          >
            <img
              style={{
                height: "250px",
                width: "380px",
                filter: "grayscale(100%)",
              }}
              className="object-cover rounded-t-xl"
              src={Shakeb}
            />
            <div className="px-8 pb-8">
              <div className="py-6">
                <div className="text-md font-semibold text-left tracking-wider">
                  Shakeb
                </div>
                <div
                  style={{ color: "#3F4C6B" }}
                  className="text-xs text-left tracking-widest font-medium leading-6"
                >
                  Designer
                </div>
              </div>
              <p>
                I am a Designer based in Delhi with a passion for people,
                Culture and Technology. I love to craft beautiful digital
                experiences focused on simplicity and purpose.
              </p>
            </div>
          </div>
          <div
            style={{ width: "380px", height: "650px" }}
            className="rounded-xl bg-white mx-5 mt-20"
          >
            <img
              style={{
                height: "250px",
                width: "380px",
                filter: "grayscale(100%)",
              }}
              className="object-cover rounded-t-xl"
              src={Nickil}
            />
            <div className="px-8 pb-8">
              <div className="py-6">
                <div className="text-md font-semibold text-left tracking-wider">
                  Nickil Mavel
                </div>
                <div
                  style={{ color: "#3F4C6B" }}
                  className="text-xs text-left tracking-widest font-medium leading-6"
                >
                  AI Mentor
                </div>
              </div>
              <p>
                Nickil Maveli is working as a part-time AI consultant overseeing
                and building the AI capabilities at Flunkey. He brings in rich
                experience in machine learning and natural language processing,
                having about 5 years of industrial experience working at
                multiple AI-based startups in India. He is currently working as
                an Applied Research Scientist at Cookpad. Before that he was a
                graduate student at the University of Edinburgh and has won
                numerous machine learning competitions in the past.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
