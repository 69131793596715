import { atom } from "recoil";

export const searchAtom = atom({
    key: "searchAtom",
    default: '',
});

export const invoiceViewAtom = atom({
    key: "invoiceViewAtom",
    default: false,
});