import moment from "moment";

// regex for Email Address input field
export const regexForEmailAddress =
  // eslint-disable-next-line no-useless-escape, no-control-regex
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

/*
  regex for Username input field
  [a-zA-Z0-9_]{5,} to match at least five alphanumerics and the underscore
  [a-zA-Z]+ to have at least one letter
  [0-9]* to match zero to any occurrence of the given numbers range
*/
export const regxForUsername = /^[a-zA-Z0-9_]{5,}[a-zA-Z]+[0-9]*$/;

/* Initial Parameter of Axios Request */
export const axiosInitialParams = {
  cache: "no-cache",
  credentials: "same-origin",
  headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  mode: "cors",
  redirect: "follow",
  referrer: "no-referrer",
  withCredentials: true,
};

/* Global Errors state utils */
export const getErrorMessage = (errors, fieldName, fieldLabel) => {
  if (errors[fieldName]) {
    const { type } = errors[fieldName];
    switch (type) {
      case "required":
        return `${fieldLabel} is required`;
      case "sameAs":
        return "Passwords do not match. Please try again.";
      case "pattern":
        return `Invalid ${fieldLabel}`;
      default:
        console.error(`Unknow error type: ${type}`);
        return type;
    }
  } else {
    return false;
  }
};

export function calcTimeAgo(endTime) {
  const current_time = new Date();

  const totalSeconds = Math.floor((current_time - endTime) / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);
  const totalDays = Math.floor(totalHours / 24);
  // console.log(totalSeconds);
  if (totalDays > 1) {
    return `${totalDays} Days ago`;
  } else if (totalHours > 1) {
    return `${totalHours} Hrs ago`;
  } else if (totalMinutes > 1) {
    return `${totalMinutes} Mins ago`;
  } else return 'Just Now'
}

export function filterForTakeAwayTable(data, search, status, date) {

  if (search)
    data = data?.filter((i) => i.orderId.toLowerCase().includes(search.toLowerCase()));

  if (status)
    data = data?.filter((i) => i.status === status);

  if (date)
    data = data?.filter((i) => moment(Date.parse(i?.createdAt)).format("DD MMM YY") == date)

  return data;
}

export function filterForDeliveryTable(data, search, status, date) {

  if (search)
    data = data?.filter((i) => i.deliveryId.toLowerCase().includes(search.toLowerCase()));

  if (status)
    data = data?.filter((i) => i.status === status);

  if (date)
    data = data?.filter((i) => moment(Date.parse(i?.createdAt)).format("DD MMM YY") == date)

  return data;
}

export function filterForDineInTabs(data, search, date, status) {

  // data = data?.filter((i) => i.status !== "ACCEPTED");

  if (search)
    data = data?.filter((i) => i.tabId.toLowerCase().includes(search.toLowerCase()));

  if (status)
    data = data?.filter((i) => i.status === status);

  if (date)
    data = data?.filter((i) => moment.unix(i?.createdAt._seconds).format("DD MMM YY") == date)

  return data;
}

export function filterReservation(data, search, date, status) {
  data = data.slice().sort((x, y) => y?.createdAt._seconds - x?.createdAt._seconds);
  if (search)
    data = data?.filter((i) => i.reservationId.toLowerCase().includes(search.toLowerCase()));

  if (status)
    data = data?.filter((i) => status === 'All' ? i : i.status.toLowerCase() === status.toLowerCase());

  if (date)
    data = data?.filter((i) => moment(i?.reservationAt).format("DD MMM YY") == date)

  return data;
}
