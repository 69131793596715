import { Tag } from 'antd'
import React from 'react'
import DineInDataTable from './DineInDataTable'

export default function DineInTable(props) {
  const columns = [
    {
      title: 'Order ID',
      key: 'orderId',
      dataIndex: 'orderId',
      width: 80,
      fixed: 'left',
    },
    {
      title: 'Tab',
      key: 'tabId',
      dataIndex: 'tabId',
      width: 90,
    },
    // {
    //   title: 'Table',
    //   key: 'tabId',
    //   dataIndex: 'customTableId',
    //   width: 100,
    // },
    // {
    //   title: 'Items',
    //   key: 'totalItems',
    //   dataIndex: 'totalItems',
    //   width: 80,
    // },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 80,
      render: (status) => (
        <Tag
          color={
            status === 'PENDING'
              ? 'red'
              : status === 'PREPARING'
              ? 'gold'
              : 'green'
          }
        >
          <span className="">{status}</span>
        </Tag>
      ),
    },
  ]
  const tabStatus = ['OPEN TABS', 'PENDING TABS', 'CLOSED TABS']
  return (
    <>
      <DineInDataTable
        columns={columns}
        tableName="Dine In"
        status={tabStatus}
        isReservation={false}

      />
    </>
  )
}
