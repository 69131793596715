import React from "react";
import "./style.scss";

export default function NotificationModal(props) {
  const { setNotificationModalVisible, children } = props;

  return (
    <>
      <div className="modal"   style={{position:"fixed",top:'21%'}}>{children}</div>
      <div className="bg" onClick={() => setNotificationModalVisible(false)} />
    </>
  );
}
