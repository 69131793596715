import { atom } from "recoil";

/*
 * @Author: Chintan Sudani
 * @Description: This file includes state
 * management recoil of Auth services.
 * @Reference:http://recoiljs.org/docs/introduction/getting-started/
 */

export const takeAwayDetailsByIdAtom = atom({
  key: "takeAwayDetailsByIdAtom",
  default: undefined,
});

export const takeAwayDataAtom = atom({
  key: "takeAwayDataAtom",
  default: undefined,
})

export const currentTakeawayStatusTabAtom = atom({
  key: "currentTakeawayStatusTabAtom",
  default: "PENDING",
});
