import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Input } from "antd";

import "./style.scss";
import { useMutation } from "react-query";
import { claimRestaurant, useAuth } from "../../services/auth";
import { toast } from "react-toastify";

export default function NoStore() {
  const history = useHistory();
  const { getIdToken, signOut } = useAuth();
  const token = getIdToken();
  const {
    mutate: claimRestaurantMutation,
    isLoading: isLoadingClaiming,
  } = useMutation((data) => claimRestaurant(data), {
    onSuccess: async (res) => {
      signOut();
      localStorage.removeItem("flunkeyAuthToken");
      localStorage.removeItem("flunkeyMerchantStore");
      history.push("/admin/overview");
      toast.success("Store Claimed Succesfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    },
  });

  const onFinish = (values) => {
    claimRestaurantMutation({ params: values, token: token });
  };

  return (
    <>
      <div className="root-nostore-landing">
        <div className="nostore-subroot-landing">
          <div className="landing-title">
            Fill in your details to get started <br />
            with
            <span className="brand-name">{""} Flunkey</span>
          </div>
          <div className="landing-subtitle">
            Fill in your restaurant details to get started on our platform
          </div>
          <div className="claim-code">
            <button
              className="common-button"
              onClick={() => history.push("/register")}
            >
              <span>Register your Restaurant</span>
            </button>
          </div>
        </div>
      </div>
      <div className="code-section">
        <div className="code-root">
          <div className="code-title">Already Have a Restaurant Code ?</div>
          <div className="code-subtitle">Enter the restaurant claim code</div>
          <Form
            name="no_store_form"
            layout="vertical"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            requiredMark={false}
            onFinish={onFinish}
          >
            <div className="root-input-box">
              <Form.Item className="root-form-item" name="code">
                <div className="input-box">
                  <Input
                    className="code-input"
                    size="large"
                    placeholder="Enter your code here"
                  />
                </div>
              </Form.Item>
              <div>
                <button type="submit" htmltype="submit" className="code-button">
                  <span>
                    {isLoadingClaiming ? "Claiming" : "Claim Restaurant"}
                  </span>
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
