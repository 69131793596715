import { Badge, Layout, Menu, Row, Switch } from "antd";
import clsx from "clsx";
import jwtDecode from "jwt-decode";
import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useMutation, useQuery } from "react-query";
import { renderRoutes } from "react-router-config";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ReactComponent as DineInIcon } from "../../assets/svgs/git-pull-request.svg";
import { ReactComponent as InventoryIcon } from "../../assets/svgs/shopping-cart.svg";
import { ReactComponent as OverViewIcon } from "../../assets/svgs/pie-chart.svg";
import { ReactComponent as ReservationIcon } from "../../assets/svgs/calendar.svg";
import { ReactComponent as TableIcon } from "../../assets/svgs/tablet.svg";
import { ReactComponent as TakeAwayIcon } from "../../assets/svgs/bag.svg";
import AdminHeader from "../../components/Navigation/AdminHeader";
import NotificationCircle from "../../components/notification_circle";
import {
  liveOrderDataAtom,
  tabDataAtom,
  waiterRequestAtom,
} from "../../recoils/dinein";
import { orderDetailsByIdAtom } from "../../recoils/orders";
import { allReservationListAtom } from "../../recoils/reservations";
import { takeAwayDataAtom } from "../../recoils/takeaway";
import {
  getNotificationDetails,
  getProfileDetails,
  updateRestaurantStatus,
  useAuth,
} from "../../services/auth";
import { getTabs } from "../../services/dinein";
import { getAllLiveOrders } from "../../services/orders";
import pusher from "../../services/pusher/pusher";
import { getAllReservations } from "../../services/reservations";
import { getAllTakeAway } from "../../services/takeaway";
import "./style.scss";
import {
  LoadingOutlined,
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { ReactComponent as DeliveryIcon } from "../../assets/svgs/truck.svg";
import { deliveryDataAtom } from "../../recoils/delivery";
import { getAllDelivery } from "../../services/delivery";
import SubMenu from "antd/lib/menu/SubMenu";
import { getActiveWaiterRequest } from "../../services/waiterRequest";
import { currentUserProfileDataAtom } from "../../recoils/auth";
import CommonModal from "../../components/Modal";
import LogOutModal from "../../components/Navigation/LogOutModal";
import Logo from "../../assets/images/brand-logo.png";

const { Header, Footer, Sider, Content } = Layout;

export default function AdminLayout(props) {
  const { route, history } = props;
  const historyDom = useHistory();
  const [isOnline, setOnline] = React.useState();
  const { getIdToken, signOut } = useAuth();
  const token = getIdToken();

  const [isLogoutModalVisible, setLogoutModalVisible] = React.useState(false);
  const [deliveryData, setDeliveryData] = useRecoilState(deliveryDataAtom);
  const [takeAwayData, setTakeAwayData] = useRecoilState(takeAwayDataAtom);
  const [liveOrdersData, setLiveOrdersData] = useRecoilState(liveOrderDataAtom);
  const setWaiterRequests = useSetRecoilState(waiterRequestAtom);
  const setMerchantProfileData = useSetRecoilState(currentUserProfileDataAtom);
  const [reservationsData, setReservationData] = useRecoilState(
    allReservationListAtom
  );
  const [tabData, setTabData] = useRecoilState(tabDataAtom);
  const [orderDetailsValues, setOrderDetailsValues] =
    useRecoilState(orderDetailsByIdAtom);

  const [collapsed, setCollapsed] = useState(false);
  const {
    data: merchantProfileData,
    isLoading: isLoadingProfile,
    isSuccess: fetchedMerchantData,
  } = useQuery("fetchProfileDetails", () => getProfileDetails(token), {
    onSuccess: (res) => {
      console.log("Fetched profile data");
      setOnline(res?.data.data?.isOnline);
      setMerchantProfileData(res?.data?.data);
    },
  });

  const handleLogOut = async () => {
    await signOut();
    localStorage.removeItem("flunkeyAuthToken");
    localStorage.removeItem("flunkeyMerchantStore");
    history.push("/");
  };

  const { mutate: updateStatus } = useMutation(
    (data) => updateRestaurantStatus(data),
    {
      onSuccess: () => {
        console.log("Updated profile data");
        setOnline(!isOnline);
      },
    }
  );

  const { data: notificationData, isSuccess: fetchedNotificationData } =
    useQuery("fetchNotificationData", () => getNotificationDetails(token));

  const { refetch: liveOrdersRefetch } = useQuery(
    "liveOrders",
    () => getAllLiveOrders(token),
    {
      onSuccess: (res) => {
        console.log("Fetched LiveOrders data");
        const sortedLiveOrderData = res?.data?.data?.slice();
        sortedLiveOrderData?.sort(
          (a, b) => +new Date(b.createdAt) - +new Date(a.createdAt)
        );
        setLiveOrdersData(sortedLiveOrderData);
      },
    }
  );

  const { refetch: takeAwayRefetch } = useQuery(
    "takeAway",
    () => getAllTakeAway(token),
    {
      onSuccess: (res) => {
        console.log("Fetched Takeaway data");
        const sortedTakeAwayData = res?.data?.data?.slice().reverse();
        setTakeAwayData(sortedTakeAwayData);
      },
    }
  );

  const { refetch: refetchDelivery } = useQuery(
    "fetchAllDeliveryData",
    () => getAllDelivery(token, 0),
    {
      onSuccess: (data) => {
        console.log("Fetched deliveries..");
        setDeliveryData(data?.data?.data);
      },
    }
  );

  const { refetch: reservationRefetch } = useQuery(
    "reservations",
    () => getAllReservations(token),
    {
      onSuccess: (res) => {
        console.log("Fetched Reservation data");
        setReservationData(res?.data?.data);
      },
    }
  );

  const { refetch: tabRefetch } = useQuery(
    "getTabs",
    () => getTabs({ token, paid: undefined, status: undefined }),
    {
      onSuccess: (res) => {
        console.log("Fetched tabs data");
        setTabData(
          res?.data?.data
            ?.slice()
            .sort((a, b) => b.createdAt._seconds - a.createdAt._seconds)
        );
      },
    }
  );

  const { refetch: refetchWaiterRequest } = useQuery(
    "fetchWaiterRequest",
    () => getActiveWaiterRequest(token),
    {
      onSuccess: (res) => {
        console.log("Waiter Request fetched Successfully");
        setWaiterRequests(res?.data?.data);
      },
    }
  );

  const handleMenuClick = (param) => {
    history.push(param.key);
  };

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  function onToggleChange(checked) {
    const query = {
      params: { isOnline: checked },
      token: token,
    };
    updateStatus(query);
  }

  React.useEffect(() => {
    token.then((token) => {
      const decodedJWT = jwtDecode(token.substring(8, token.length));
      if (decodedJWT?.store) {
        var channel = pusher.subscribe(decodedJWT?.store);

        const audio = new Audio(
          "https://upload.wikimedia.org/wikipedia/commons/3/34/Sound_Effect_-_Door_Bell.ogg"
        );

        channel.bind("take-away", function (data) {
          toast.success("New takeaway order of " + data?.totalItems + " items");
          audio.play();
          takeAwayRefetch();
        });
        channel.bind("delivery", function (data) {
          // console.log(data);
          if (data?.paid) {
            toast.success(
              "New delivery order of " + data?.items.length + " items"
            );
            audio.play();
            refetchDelivery();
          }
        });
        channel.bind("live", function (data) {
          toast.success("New Dine in order of " + data?.totalItems + " items");
          audio.play();
          liveOrdersRefetch();
        });
        channel.bind("new-reservations", function (data) {
          toast.success("New reservation for" + data?.pax + " people");
          audio.play();
          reservationRefetch();
        });
        channel.bind("new-tab", function (data) {
          toast.success("New tab opened with Id " + data?.tabId);
          tabRefetch();
          audio.play();
        });
        channel.bind("closed-tab", function (data) {
          toast.success("Tab closed succesfully by user of Id " + data?.tabId);
          tabRefetch();
          audio.play();
        });
        channel.bind("waiter-request", function (data) {
          // console.log(data);
          toast.success("Waiter Request");
          audio.play();
          refetchWaiterRequest();
        });
        channel.bind("payment-status-dinein", function (data) {
          // console.log(data);
          if (data?.paid) {
            toast.success(`Tab ${data?.tabId} paid succesfully`);
            tabRefetch();
            audio.play();
          }
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="admin-layout">
      {isLoadingProfile && (
        <div
          className="h-screen w-screen z-10 text-white flex absolute justify-center items-center"
          style={{ background: "rgba(0,0,0,0.5)" }}
        >
          <LoadingOutlined style={{ fontSize: 100, color: "#ff8d0b" }} />
        </div>
      )}
      <Layout>
        <Sider
          theme="light"
          width={250}
          trigger={null}
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          collapsedWidth="100px"
          breakpoint="xl"
          onBreakpoint={() => {
            setCollapsed(false);
          }}
          style={{
            overflow: "auto",
            // height: "100vh",
          }}
        >
          {/* <div className={collapsed ? "my-16" : "logo"} /> */}
          <div className="justify-center">
            <img src={Logo} className={`m-auto my-4 ${collapsed && "w-16"}`} />
          </div>
          <Menu
            theme="light"
            mode="inline"
            onClick={handleMenuClick}
            className="text-base"
          >
            <Menu.Item
              key="/admin/overview"
              icon={<OverViewIcon />}
              className={clsx(
                historyDom?.location?.pathname === "/admin/overview" &&
                  "active-admin-menu"
              )}
            >
              Overview
            </Menu.Item>
            <Menu.Item
              key="/admin/dine-in"
              icon={
                <div>
                  <DineInIcon className="mt-2" />
                  <Badge
                    className="absolute top-0 right-4"
                    count={
                      !collapsed
                        ? 0
                        : tabData?.filter(
                            (i) =>
                              i.status === "ACCEPTED" || i.status === "PENDING"
                          ).length
                    }
                  />
                </div>
              }
              className={clsx(
                historyDom?.location?.pathname === "/admin/dine-in" &&
                  "active-admin-menu"
              )}
            >
              Dine In
              <Badge
                className="mx-4"
                count={
                  tabData?.filter(
                    (i) => i.status === "ACCEPTED" || i.status === "PENDING"
                  ).length
                }
              />
            </Menu.Item>
            <Menu.Item
              key="/admin/take-away"
              icon={
                <div>
                  <TakeAwayIcon className="mt-2" />
                  <Badge
                    className="absolute top-0 right-4"
                    count={
                      !collapsed
                        ? 0
                        : takeAwayData?.filter(
                            (i) =>
                              i.status != "MERCHANT_DECLINED" &&
                              i.status !== "COMPLETED"
                          ).length
                    }
                  />
                </div>
              }
              className={clsx(
                historyDom?.location?.pathname === "/admin/take-away" &&
                  "active-admin-menu"
              )}
            >
              TakeAway
              <Badge
                className="mx-4"
                count={
                  takeAwayData?.filter(
                    (i) =>
                      i.status != "MERCHANT_DECLINED" &&
                      i.status !== "COMPLETED"
                  ).length
                }
              />
            </Menu.Item>
            <Menu.Item
              key="/admin/delivery"
              icon={
                <div>
                  <DeliveryIcon className="mt-2" />
                  <Badge
                    className="absolute top-0 right-4"
                    count={
                      !collapsed
                        ? 0
                        : deliveryData?.response?.filter(
                            (i) =>
                              i.status != "MERCHANT_DECLINED" &&
                              i.status !== "COMPLETED"
                          ).length
                    }
                  />
                </div>
              }
              className={clsx(
                historyDom?.location?.pathname === "/admin/delivery" &&
                  "active-admin-menu"
              )}
            >
              Delivery
              <Badge
                className="mx-4"
                count={
                  deliveryData?.response?.filter(
                    (i) =>
                      i.status != "MERCHANT_DECLINED" &&
                      i.status !== "COMPLETED"
                  ).length
                }
              />
            </Menu.Item>
            <Menu.Item
              key="/admin/reservations"
              icon={
                <div>
                  <ReservationIcon className="mt-2" />
                  <Badge
                    className="absolute top-0 right-4"
                    count={
                      !collapsed
                        ? 0
                        : reservationsData?.filter(
                            (i) => i.status === "PENDING"
                          ).length
                    }
                  />
                </div>
              }
              className={clsx(
                historyDom?.location?.pathname === "/admin/reservations" &&
                  "active-admin-menu"
              )}
            >
              Reservations
              <Badge
                className="mx-4"
                count={
                  reservationsData?.filter((i) => i.status === "PENDING").length
                }
              />
            </Menu.Item>
            <SubMenu
              key="submenu"
              icon={<UserOutlined style={{ fontSize: "1.5rem" }} />}
              title="My Restaurant"
              className="ant-menu-submenu"
            >
              <Menu.Item
                key="/admin/inventory"
                icon={<InventoryIcon className="inline-block" />}
                className={clsx(
                  historyDom?.location?.pathname === "/admin/inventory" &&
                    "active-admin-menu"
                )}
              >
                Inventory
              </Menu.Item>
              <Menu.Item
                key="/admin/tables"
                icon={<TableIcon className="inline-block" />}
                className={clsx(
                  historyDom?.location?.pathname === "/admin/tables" &&
                    "active-admin-menu"
                )}
              >
                Tables
              </Menu.Item>
              <Menu.Item
                key="/admin/agent"
                icon={<TeamOutlined style={{ fontSize: "1.5rem" }} />}
                className={clsx(
                  historyDom?.location?.pathname === "/admin/agent" &&
                    "active-admin-menu"
                )}
              >
                Agents
              </Menu.Item>
              <Menu.Item  
                key="/admin/profile"
                icon={<UserOutlined style={{ fontSize: "1.5rem" }} />}
                className={clsx(
                  historyDom?.location?.pathname === "/admin/profile" &&
                    "active-admin-menu"
                )}
              >
                Restaurant Profile
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout className="admin-layout">
          <Header className="bg-white border-b" style={{ padding: 0 }}>
            <Row justify="space-between">
              <div>
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "text-2xl pl-4",
                    onClick: toggle,
                  }
                )}
                <span className="pl-10 text-2xl">
                  {fetchedMerchantData &&
                    merchantProfileData &&
                    merchantProfileData?.data?.data?.restaurantName}{" "}
                </span>
                <sub className="text-xs">
                  powered by{" "}
                  <span className="font-bold gradient-reverse bg-clip-text">
                    Flunkey{" "}
                  </span>
                </sub>
              </div>
              <div>
                <Switch checked={isOnline} onChange={onToggleChange} />
                <span className="text-black ml-5 mr-10">
                  {isOnline ? "Online" : "Offline"}
                </span>
                <button
                  onClick={() => {
                    setLogoutModalVisible(true);
                  }}
                  className="mr-4 text-md "
                >
                  <LogoutOutlined style={{ fontSize: 24 }} />
                  &nbsp; Logout
                </button>
              </div>
            </Row>
          </Header>
          <Content className="admin-layout-background">
            {renderRoutes(route.routes)}
          </Content>
          {/* <Footer className=" text-center">
          Flunkey ©2021 Created by Flunkey Web Team
        </Footer> */}
        </Layout>
      </Layout>

      <CommonModal
        isModalOpen={isLogoutModalVisible}
        handleCancel={() => setLogoutModalVisible(false)}
        modalWidth={320}
      >
        <LogOutModal
          setLogoutModalVisible={setLogoutModalVisible}
          handleMenuClick={handleLogOut}
        />
      </CommonModal>
    </div>
  );
}
