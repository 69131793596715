import Axios from "axios";
import { axiosInitialParams } from "../../utils";

const baseAPIUrl = process.env.REACT_APP_API_SERVER;
const axios = Axios.create({
  baseURL: baseAPIUrl,
  axiosInitialParams,
});

// return all Live Orders
export const getActiveWaiterRequest = async (token) => {
  token = await token;
  return axios.get("/merchant/requestWaiter", {
    headers: { Authorization: "Bearer " + token },
  });
};

// returns details of order by orderId
export const updateWaiterRequest = async ({ waiterRequestId, token, body }) => {
  token = await token;
  return axios.put(`/merchant/requestWaiter/${waiterRequestId}`, body, {
    headers: { Authorization: "Bearer " + token },
  });
};
