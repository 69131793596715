import moment from "moment";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useRecoilState, useSetRecoilState } from "recoil";
import CommonModal from "../../../components/Modal";
import { useAuth } from "../../../services/auth";
import {
  getAllReservations,
  updateReservationStatus,
  addReservation
} from "../../../services/reservations";
import ReservationsDataTable from "./ReservationsTable/ReservationsDataTable";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { searchAtom } from "../../../recoils/helperAtoms";
import { AssignTableModal } from "./assignTableModal";
import { Popconfirm } from "antd";
import { allReservationListAtom } from "../../../recoils/reservations";

export default function Reservations() {
  const { getIdToken } = useAuth();
  const token = getIdToken();
  const setSearch = useSetRecoilState(searchAtom);
  const [reservationData, setReservationData] = useRecoilState(
    allReservationListAtom
  );
  const reservationStatus = [
    "All",
    "Pending",
    "Accepted",
    "Rejected",
    "Cancelled_by_user",
  ];
  const [isAssignTableModal, setIsAssignTableModal] = React.useState(false);
  const [selectedReservation, setSelectedReservation] = React.useState();
  const handleCancel = () => setIsAssignTableModal(false);
  const showAssignTableModal = () => setIsAssignTableModal(true);

  const { refetch: refetchReservationData } = useQuery(
    "fetchAllReservations",
    async () => await getAllReservations(token),
    {
      onSuccess: (data) => {
        if (data.data.statusCode === 200) {
          console.log("Reservation Data fetched successfully");
          setReservationData(data?.data?.data);
        }
      },
    }
  );
  const { mutate: updateReservationMutation } = useMutation(
    (body) => updateReservationStatus(body),
    {
      onSuccess: (data) => {
        console.log("Reservation Data Updated successfully");
        if (data.data.data.status === "ACCEPTED")
          toast.success(
            `Reservation ${data.data.data.reservationId} is Accepted`
          );
        else
          toast.success(
            `Reservation ${data.data.data.reservationId} is Rejected`
          );
        refetchReservationData();
      },
    }
  );
  const addNewReservation = async (body) => {
    await addReservation(body, token);
    refetchReservationData();
  };
  React.useEffect(() => setSearch());

  const handleAccept = (reservation) => {
    setSelectedReservation(reservation);
    showAssignTableModal();
  };

  const handleReject = async (reservation) => {
    // console.log(reservation);
    const body = {
      token: token,
      reservationId: reservation?.reservationId,
      params: {
        status: "REJECTED",
        tableId: "",
        customTableId: "",
      },
    };
    updateReservationMutation(body);
  };

  const onAssignTable = async (reservation, table) => {
    const body = {
      token: token,
      reservationId: reservation?.reservationId,
      params: {
        status: "ACCEPTED",
        tableId: table.tableId,
        customTableId: table.customTableId,
      },
    };
    // console.log(body);
    updateReservationMutation(body);
    handleCancel();
  };

  const columns = [
    {
      title: "Reservation At",
      key: "reservationAt",
      width: 180,
      render: (reservationData) => (
        <>
          {moment(reservationData?.reservationAt).format("DD MMM YYYY h:mm A")}
        </>
      ),
    },
    {
      title: "Reservation Id",
      key: "reservationId",
      dataIndex: "reservationId",
      width: 140,
    },
    {
      title: "Table No",
      key: "tableId",
      width: 120,
      dataIndex: "tableId",
    },
    {
      title: "Table Name",
      key: "customTableId",
      width: 120,
      dataIndex: "customTableId",
    },
    {
      title: "Pax",
      key: "pax",
      width: 60,
      dataIndex: "pax",
    },
    {
      title: "Contact No",
      key: "phonenumber",
      width: 150,
      dataIndex: "phonenumber",
    },
    // {
    //   title: "CREATED AT",
    //   key: "createdAt",
    //   render: (reservationData) => (
    //     <>{moment.unix(reservationData?.createdAt._seconds).format("DD MMM YYYY : HH:mm")}</>
    //   ),
    // },
    {
      title: "Status",
      width: 150,
      fixed: "right",
      key: "status",
      render: (reservation) =>
        reservation?.status.toUpperCase() === "PENDING" ? (
          <div className="grid grid-cols-2 gap-2 w-auto">
            <button
              className="rounded-full h-8 w-8 flex items-center justify-center shadow	"
              onClick={() => handleAccept(reservation)}
            >
              <CheckOutlined
                className="text-green-400"
                style={{ fontSize: "18px" }}
              />
            </button>
            <Popconfirm
              title="Are you sure you want to reject this reservation"
              okText="Yes"
              onConfirm={() => handleReject(reservation)}
            >
              <button className="rounded-full h-8 w-8 flex items-center justify-center shadow	">
                <CloseOutlined
                  className="text-red-400"
                  style={{ fontSize: "18px" }}
                />
              </button>
            </Popconfirm>
          </div>
        ) : (
          <>{reservation?.status.toUpperCase().replaceAll("_", " ")}</>
        ),
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   padding: '0',
    //   render: (reservationData) => {
    //     if (reservationData.status === "PENDING")
    //       return (

    //       );
    //   },
    // },
  ];

  return (
    <>
      <ReservationsDataTable
        columns={columns}
        data={reservationData}
        tableName={`Reservations`}
        status={reservationStatus}
        addNewReservation={addNewReservation}
      />
      <CommonModal
        title="Assign Table"
        isModalOpen={isAssignTableModal}
        handleCancel={handleCancel}
        destroyOnClose={true}
        modalWidth={1200}
      >
        <AssignTableModal
          reservation={selectedReservation}
          onAssignTable={onAssignTable}
        />
      </CommonModal>
    </>
  );
}
