import { atom } from "recoil";

/*
 * @Author: Chintan Sudani
 * @Description: This file includes state
 * management recoil of Auth services.
 * @Reference:http://recoiljs.org/docs/introduction/getting-started/
 */

export const deliveryDetailsByIdAtom = atom({
    key: "deliveryDetailsByIdAtom",
    default: undefined,
});

export const deliveryDataAtom = atom({
    key: "deliveryDataAtom",
    default: undefined,
})

export const currentDeliveryStatusTabAtom = atom({
    key: "currentDeliveryStatusTabAtom",
    default: "PENDING",
});
