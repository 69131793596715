import {
  CheckCircleFilled,
  FileImageOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Row } from "antd";
import React from "react";
import ReactCrop from "react-image-crop";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import OutlinedButton from "../../../components/CustomButtons/OutlinedButton";
import PrimaryButton from "../../../components/CustomButtons/PrimaryButton";
import { addRestaurantProfileImage, useAuth } from "../../../services/auth";

export default function UploadImageModal(props) {
  const { setUploadImageModalVisible, refetch } = props;
  const { getIdToken } = useAuth();
  const authToken = getIdToken();
  const [crop, setCrop] = React.useState({ aspect: 1.8 / 1, height: 110 });
  const [srcImg, setSrcImg] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const [result, setResult] = React.useState(null);
  const { mutate: uploadProfileImageMutation } = useMutation(
    (data) => addRestaurantProfileImage(data),
    {
      onSuccess: () => {
        console.log("Image Uploaded succesfully");
        refetch();
        setUploadImageModalVisible(false);
      },
    }
  );

  function onUploadRestaurantProfileImage() {
    uploadProfileImageMutation({
      params: { imageRaw: result },
      token: authToken,
    });
  }

  const handleImageUpload = async (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    console.log(event.target.files[0]);
  };

  const getCroppedImg = async () => {
    try {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);
      setResult(base64Image);
      console.log(result);
    } catch (e) {
      console.log("crop the image");
    }
  };

  return (
    <>
      <div align="center">
        <div>
          {!result ? (
            !srcImg ? (
              <label htmlFor="photo-upload">
                <Row className="h-48" justify="center" align="middle">
                  <div
                    align="center"
                    className="text-base rounded-full shadow-lg px-4 py-2 "
                  >
                    <PlusOutlined style={{ color: "#FF5317" }} />
                    <span align="center" className="pl-2">
                      Add Image
                    </span>
                  </div>
                  <div>
                    <input
                      id="photo-upload"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                    />
                  </div>
                </Row>
              </label>
            ) : (
              <div className=" mx-auto selected-image w-3/5">
                <ReactCrop
                  src={srcImg}
                  onImageLoaded={setImage}
                  crop={crop}
                  onChange={setCrop}
                />
              </div>
            )
          ) : (
            <div>
              <img src={result} className="w-full" />
            </div>
          )}
        </div>
      </div>
      <Row justify="end" className="mt-5">
        {result ? (
          <PrimaryButton
            text="Submit"
            onClick={onUploadRestaurantProfileImage}
          />
        ) : srcImg ? (
          <OutlinedButton text="Done" handleClick={getCroppedImg} />
        ) : (
          <span></span>
        )}
      </Row>
    </>
  );
}
