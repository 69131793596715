import { DeleteFilled } from "@ant-design/icons";
import { Col, Popconfirm, Row, Table } from "antd";
import moment from "moment";
import React from "react";
import { useAuth } from "../../../services/auth";
import clsx from "clsx";

const PrintDetails = React.forwardRef((props, ref) => {
  const { getIdToken } = useAuth();
  const token = getIdToken();
  return (
    <div className="p-5 text-center flex flex-col" style={{ height: "90vh" }}>
      <div className="flex-none">
        <div className="p-2">
          <h2 className="text-xl font-bold">
            {props?.orderInvoiceData?.data?.data?.restaurantName}
          </h2>
          <div className="text-md">
            {props?.orderInvoiceData?.data?.data?.restaurantAddress}
          </div>
        </div>

        <hr style={{ borderTop: "1px dashed" }} />

        <Row justify="space-between" className="p-2">
          <Col>Bill No : {props?.orderInvoiceData?.data?.data?.orderId}</Col>
          <Col>
            {moment(
              Date.parse(props?.orderInvoiceData?.data?.data?.createdAt)
            ).format("DD MMMM YYYY hh:mm")}
          </Col>
        </Row>

        <hr style={{ borderTop: "1px dashed" }} />
      </div>

      <div className="p-2 flex-grow">
        <Table
          size="small"
          pagination={{ position: ["none"] }}
          columns={[
            {
              title: "Name",
              dataIndex: "itemName",
              key: "name",
              width: 80,
            },
            {
              title: "Qty",
              dataIndex: "quantity",
              key: "qty",
              width: 40,
            },
            {
              title: "Price",
              dataIndex: "price",
              key: "price",
              render: (price) => {
                return Number(price).toFixed(2);
              },
              width: 60,
            },
            {
              title: "Total",
              key: "total",
              dataIndex: "total",
              render: (total) => {
                return Number(total).toFixed(2);
              },
              width: 60,
            },
          ]}
          dataSource={props?.orderInvoiceData?.data?.data?.items}
          className="table-white"
        />
        <hr style={{ borderTop: "1px dashed" }} />
        <Row justify="space-between">
          <Col className="text-left text-gray-600 font-medium">
            <div className="py-2">Cart Value</div>
            <div className="py-2">Service Charge</div>
            <div className="py-2">GST</div>
            {/* <div className="py-2">Tip</div> */}
          </Col>
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">
              $ {props?.orderInvoiceData?.data?.data?.totalCart?.toFixed(2)}
            </div>
            <div className="py-2">
              $ {props?.orderInvoiceData?.data?.data?.serviceCharge?.toFixed(2)}
            </div>
            <div className="py-2">
              $ {props?.orderInvoiceData?.data?.data?.gst?.toFixed(2)}
            </div>
            {/* <div className="py-2">
              $ {props?.orderInvoiceData?.data?.data?.tip?.toFixed(2)}
            </div> */}
          </Col>
        </Row>
        <hr style={{ borderTop: "1px dashed" }} />
        <Row justify="space-between">
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">Total</div>
          </Col>
          <Col className="text-center text-gray-600 font-medium">
            <div className="py-2">
              $ {props?.orderInvoiceData?.data?.data?.total?.toFixed(2)}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
});
export default PrintDetails;
