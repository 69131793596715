import { TextField } from "@mui/material";
import { Checkbox, Form, Input } from "antd";
import { regexForEmailAddress } from "../../../utils";
import FormItem from "antd/lib/form/FormItem";
import React from "react";
import PrimaryButton from "../../../components/CustomButtons/PrimaryButton";
import { useMutation } from "react-query";
import { claimRestaurant, useAuth } from "../../../services/auth";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const OwnerDetails = () => {
  const [isCode, setIsCode] = React.useState(false);
  const [code, setCode] = React.useState("");
  const history = useHistory();
  const { getIdToken, signOut } = useAuth();
  const token = getIdToken();
  const { mutate: claimRestaurantMutation, isLoading: isLoadingClaiming } =
    useMutation((data) => claimRestaurant(data), {
      onSuccess: async (res) => {
        await signOut();
        localStorage.removeItem("flunkeyAuthToken");
        localStorage.removeItem("flunkeyMerchantStore");
        history.push("/");
        toast.success("Store Claimed Succesfully!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
    });
  const onClick = (e) => {
    e.preventDefault();
    console.log(code);
    claimRestaurantMutation({ params: { code: code }, token: token });
  };
  return (
    <>
      <h1 className="text-2xl pb-2 tracking-wide font-semibold">
        {!isCode ? "Owner's Detail" : "Claim Restaurant"}
      </h1>
      {!isCode ? (
        <div className="text-xs pb-2">
          Please make sure the following details are correct, so that
          <br /> we can serve you better.
        </div>
      ) : (
        <div className="text-xs pb-2">
          Please make sure the Claim Code is correct
        </div>
      )}
      {!isCode && (
        <div>
          <Form.Item
            name="owner"
            required={false}
            rules={[{ required: true, message: "Required" }]}
          >
            <TextField
              label="Name"
              className="w-full input"
              variant="standard"
              color="warning"
            />
          </Form.Item>
          <Form.Item
            name="ownerEmail"
            required={false}
            rules={[
              {
                required: true,
                message: "Required",
                pattern: regexForEmailAddress,
              },
            ]}
          >
            <TextField
              label="Email"
              className="w-full input"
              variant="standard"
              color="warning"
            />
          </Form.Item>
          <Form.Item
            name="ownerMobile"
            required={false}
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <TextField
              label="Mobile No."
              className="w-full input"
              variant="standard"
              placeholder="9340248598"
              color="warning"
              type="number"
            />
          </Form.Item>
        </div>
      )}
      <Checkbox
        onChange={(e) => {
          setIsCode(e.target.checked);
        }}
        checked={isCode}
      />
      <span className="pl-2 font-medium">I Already Have a Restaurant Code</span>
      {isCode && (
        <div>
          <Input
            className="rounded-md mt-2"
            placeholder="Enter Code to claim your Outlet"
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
      )}
      <Form.Item>
        <div align="center" className="pt-6">
          <PrimaryButton
            className="w-full"
            reverseGradient
            disabled={(isCode && code.trim() == "") || isLoadingClaiming}
            onClick={isCode && onClick}
            type="submit"
            text={isCode ? "CLAIM RESTAURANT" : "PROCEED TO BUSINESS DETAILS"}
          />
        </div>
      </Form.Item>
    </>
  );
};

export default OwnerDetails;
