import React from "react";

export default function RoundedButton(props) {
  return (
    <button
      className="text-base rounded-full shadow-md px-4"
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
