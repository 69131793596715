import {
	CategoryScale,
	Chart,
	Filler,
	LinearScale,
	LineController,
	LineElement,
	PointElement,
	TimeScale,
	Tooltip
} from "chart.js";
import "chartjs-adapter-moment";
import React, { useEffect, useRef } from "react";

Chart.register(
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip,
  CategoryScale
);

export default function LineChart() {
  const canvas = useRef(null);
  useEffect(() => {
    const ctx = canvas.current;
    const labels = ["January", "February", "March", "April", "May", "June"];
    const data = {
      labels: labels,
      datasets: [
        {
          label: "Total sales ",
          backgroundColor: "rgba(255, 146, 10, 0.5)",
          borderColor: "rgba(255, 146, 10, 0.5)",
          data: [0, 10, 5, 2, 20, 30, 45],
          fill: true,
          tension: 0.5,
        },
      ],
    };

    const configLineChart = {
      type: "line",
      data,
      scaleSteps: 5,
      scaleStepWidth: 50,
      suggestedMin: 50,
      suggestedMax: 50,
      options: {
        maintainAspectRatio: false,
        scales: {

          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: true,
            },
          },
        },
      },
    };

    var chartLine = new Chart(ctx, configLineChart);
    return () => chartLine.destroy();
  }, []);

  return <canvas ref={canvas}></canvas>;
}
