import * as React from "react";
import Box from "@mui/material/Box";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DatePicker";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import moment from "moment";
import { CloseOutlined } from '@ant-design/icons';

export default function CustomDatePicker(props) {
    const { value, setValue } = props
    const color = "#ff920a"

    const MyDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
        color: theme.palette.primary,
    }));

    const theme = createTheme({
        palette: {
            primary: {
                main: color,
            },
        },
    });

    return value == null ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
                <MyDatePicker
                    label="Custom input"
                    value={null}
                    onChange={(newValue) => {
                        setValue(moment(newValue).format("DD MMM YY"));
                    }}
                    autoFocus={false}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box sx={{ display: "flex", alignItems: "center", svg: { color } }}>
                            <input className="w-0" ref={inputRef} />
                            {InputProps?.endAdornment}
                        </Box>
                    )}
                />
            </ThemeProvider>
        </LocalizationProvider>
    ) : (
        <button
            onClick={() => setValue()}
            className=" mx-5 border font-medium rounded px-5 text-white align-middle flex"
            style={{ background: color }}
        >
            {value}
            <CloseOutlined className="my-1" style={{ fontSize: '14px', paddingLeft: '10px' }} />
        </button>
    );
}
