import {
  Checkbox,
  Col,
  Collapse,
  Descriptions,
  Popconfirm,
  Row,
  Switch,
  Table,
  Tag,
} from 'antd'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useReactToPrint } from 'react-to-print'
import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'
import OrderDetailsItem from '../../../components/OrderDetailsCard/order_details_item'
import OrderDetailsCard from '../../../components/OrderDetailsCard/order_details_card'
import { currentTabIdAtom, waiterRequestAtom } from '../../../recoils/dinein'
import { orderDetailsByIdAtom } from '../../../recoils/orders'
import { useAuth } from '../../../services/auth'
import {
  getInvoiceByTabId,
  removeItemFromInvoice,
  updateLiveOrderStatus,
  updateTabStatus,
} from '../../../services/dinein'
import KOTDetails from './KOTDetails'
import InvoiceDetails from './InvoiceDetails'
import PrintDetails from './PrintDetails'
import PrimaryButton from '../../../components/CustomButtons/PrimaryButton'
import DeclineButton from '../../../components/CustomButtons/declineButton'
import { Colors } from '../../../utils/colors'
import OutlinedButton from '../../../components/CustomButtons/OutlinedButton'
import { updateWaiterRequest } from '../../../services/waiterRequest'
import { invoiceViewAtom } from '../../../recoils/helperAtoms'
import ItemDetail from './ItemDetail'

export default function DineInOrderDetails(props) {
  const { refetchTabs, refetchLiveOrders, refetchWaiterRequest } = props
  const { getIdToken } = useAuth()
  const token = getIdToken()
  const [invoiceView, setInvoiceView] = useRecoilState(invoiceViewAtom)
  const [tableHeight, setTableHeight] = useState()
  const [waiterRequest, setWaiterRequest] = useState()
  const ref = React.useRef()
  const descriptionRef = React.useRef()
  const bottomButtonRef = React.useRef()
  const {Panel}  =Collapse;

  // Recoils
  const [currentTab, setCurrentTab] = useRecoilState(currentTabIdAtom)
  const [waiterRequestList, SetWaiterRequestList] = useRecoilState(
    waiterRequestAtom,
  )
  const [orderDetailsValues, setOrderDetailsValues] = useRecoilState(
    orderDetailsByIdAtom,
  )

  // Mutations
  const {
    mutate: getInvoiceDetailsMutation,
    data: orderInvoiceData,
  } = useMutation((data) => getInvoiceByTabId(data), {
    onSuccess: () => {
      console.log('Fetched order Invoice Data successfully')
    },
  })
  const { mutate: updateLiveOrderMutation, isLoading } = useMutation(
    (data) => updateLiveOrderStatus(data),
    {
      onSuccess: (res) => {
        console.log('Updated Live Order successfully')
        refetchLiveOrders()
        setOrderDetailsValues(res?.data?.data)
        toast.success('Order is ' + res?.data?.data?.status.toLowerCase())
      },
    },
  )

  const { mutate: updateTabMutation } = useMutation(
    (data) => updateTabStatus(data),
    {
      onSuccess: () => {
        console.log('Updated Tab successfully')
        refetchTabs()
      },
    },
  )

  const { mutate: removeItemFromInvoiceMutation } = useMutation(
    (data) => removeItemFromInvoice(data),
    {
      onSuccess: () => {
        console.log('Removed Item from invoice successfully')
        getInvoiceDetailsMutation({ token: token, tabId: currentTab?.tabId })
      },
    },
  )

  const { mutate: updateWaiterRequestMutation } = useMutation(
    (data) => updateWaiterRequest(data),
    {
      onSuccess: () => {
        console.log('Updated Waiter Request successfully')
        refetchWaiterRequest()
      },
    },
  )

  // Helpers
  const updateItems = (index, checked) => {
    const array = [...orderDetailsValues?.items]
    if (checked) {
      array.push(orderDetailsValues.items?.filter((i) => i.index === index))
      // setItems((items) => [...array]);
    } else {
      // setItems((items) => items?.filter((i) => i.index !== index));
    }
  }

  const handleViewInvoice = () => {
    setViewKOT(false)
    getInvoiceDetailsMutation({
      token: token,
      tabId: currentTab?.tabId,
    })
    setInvoiceView(!invoiceView)
  }

  const [viewKOT, setViewKOT] = useState(false)

  const handleViewKOT = () => {
    handleViewInvoice()
    setViewKOT(true)
  }

  const handleContinue = () => {
    const nextStatus = getNextStatus(orderDetailsValues?.status)
    const body = {
      status: nextStatus,
      items: orderDetailsValues?.items,
    }
    updateLiveOrderMutation({
      token: token,
      itemId: orderDetailsValues?.orderId,
      params: body,
    })
  }

  const getPrimaryButtonText = (status) => {
    switch (status) {
      case 'PENDING':
        return 'Preparing'
      case 'PREPARING':
        return 'Prepared'
      case 'PREPARED':
        return 'Completed'
      case 'COMPLETED':
        return undefined
      default:
        return undefined
    }
  }

  function getNextStatus(status) {
    switch (status) {
      case 'PENDING':
        return 'PREPARING'
      case 'PREPARING':
        return 'COMPLETED'
      case 'COMPLETED':
      default:
        return undefined
    }
  }

  // function getStatus(status) {
  //   var base =
  //     'inline-flex items-center  border border-transparent text-s font-medium rounded px-2 py-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 '
  //   switch (status) {
  //     case 'PENDING':
  //       return base + 'bg-yellow-100'
  //     case 'PREPARING':
  //       return base + 'bg-green-100'
  //     case 'COMPLETED':
  //       return base + 'bg-red-100'
  //     default:
  //       return ''
  //   }
  // }

 

  React.useEffect(() => {
    setTableHeight(
      descriptionRef.current?.clientHeight +
        bottomButtonRef.current?.clientHeight,
    )
  })
  React.useMemo(() => {
    setWaiterRequest(
      waiterRequestList?.find?.(
        (request) => request?.tabId === currentTab?.tabId,
      ),
    )
  }, [waiterRequestList, currentTab])

  return (
    <>
      {!invoiceView ? (
        <OrderDetailsCard>
          <div ref={descriptionRef} className="flex-none">
          <Descriptions
              bordered
              labelStyle={{ backgroundColor: 'white', color: 'grey' }}
              title={
                <Row
                  className="pt-5"
                  justify="space-around"
                  align="middle"
                  gutter={[0, 8]}
                >
                  <Col className="px-5 text-lg ">Order Details</Col>
                  <Col>
                    <Row justify="space-between" align="middle" gutter={8}>
                      <Col>
                        {currentTab?.tabOpen &&
                          currentTab?.status == 'ACCEPTED' && (
                            <Popconfirm
                              title="Are you sure to perform this action?"
                              onConfirm={() => {
                                updateTabMutation({
                                  token: token,
                                  tabId: currentTab?.tabId,
                                  params: {
                                    status: 'COMPLETED',
                                    paid: true,
                                    paymentMode: 'CASH',
                                  },
                                })
                              }}
                              okText="Close Tab"
                            >
                              <button className="px-2 py-2 text-white bg-red-500 rounded-md text-xs tracking-wide">
                                <span>CLOSE TAB/ PAID IN CASH</span>
                              </button>
                            </Popconfirm>
                          )}
                      </Col>
                      <Col>
                        {currentTab?.status === 'PENDING' && (
                          <Popconfirm
                            title="Are you sure to perform this action?"
                            okText="Yes"
                            onConfirm={() => {
                              updateTabMutation({
                                token: token,
                                tabId: currentTab?.tabId,
                                params: {
                                  status: 'MERCHANT_DECLINED',
                                  tabOpen: false,
                                },
                              })
                            }}
                          >
                            <button className="px-4 py-1 text-white bg-red-500 rounded-md text-sm tracking-wide">
                              <span>Reject</span>
                            </button>
                          </Popconfirm>
                        )}
                      </Col>
                      <Col>
                        {currentTab?.status === 'PENDING' && (
                          <PrimaryButton
                            horizontalPadding={4}
                            onClick={() => {
                              updateTabMutation({
                                token: token,
                                tabId: currentTab?.tabId,
                                params: {
                                  status: 'ACCEPTED',
                                  tabOpen: true,
                                },
                              })
                            }}
                            text="Accept"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
              size="small"
              column={1}
            />

            
          <Collapse defaultActiveKey={'1'}>
            <Panel header ="View Order Details" key={orderDetailsValues ? "0": "1"}>
            {/* <Descriptions
              bordered
              labelStyle={{ backgroundColor: 'white', color: 'grey' }}
              title={
                <Row
                  className="pt-5"
                  justify="space-around"
                  align="middle"
                  gutter={[0, 8]}
                >
                  <Col className="px-5 text-lg ">Order Details</Col>
                  <Col>
                    <Row justify="space-between" align="middle" gutter={8}>
                      <Col>
                        {currentTab?.tabOpen &&
                          currentTab?.status == 'ACCEPTED' && (
                            <Popconfirm
                              title="Are you sure to perform this action?"
                              onConfirm={() => {
                                updateTabMutation({
                                  token: token,
                                  tabId: currentTab?.tabId,
                                  params: {
                                    status: 'COMPLETED',
                                    paid: true,
                                    paymentMode: 'CASH',
                                  },
                                })
                              }}
                              okText="Close Tab"
                            >
                              <button className="px-2 py-2 text-white bg-red-500 rounded-md text-xs tracking-wide">
                                <span>CLOSE TAB/ PAID IN CASH</span>
                              </button>
                            </Popconfirm>
                          )}
                      </Col>
                      <Col>
                        {currentTab?.status === 'PENDING' && (
                          <Popconfirm
                            title="Are you sure to perform this action?"
                            okText="Yes"
                            onConfirm={() => {
                              updateTabMutation({
                                token: token,
                                tabId: currentTab?.tabId,
                                params: {
                                  status: 'MERCHANT_DECLINED',
                                  tabOpen: false,
                                },
                              })
                            }}
                          >
                            <button className="px-4 py-2 text-white bg-red-500 rounded-md text-sm tracking-wide">
                              <span>Reject</span>
                            </button>
                          </Popconfirm>
                        )}
                      </Col>
                      <Col>
                        {currentTab?.status === 'PENDING' && (
                          <PrimaryButton
                            horizontalPadding={4}
                            onClick={() => {
                              updateTabMutation({
                                token: token,
                                tabId: currentTab?.tabId,
                                params: {
                                  status: 'ACCEPTED',
                                  tabOpen: true,
                                },
                              })
                            }}
                            text="Accept"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
              size="small"
              column={1}
            >  */}
            <Descriptions bordered
              labelStyle={{ backgroundColor: 'white', color: 'grey' }} size="small"
              column={1}>
              
              
              <Descriptions.Item label="Order Id">
                {orderDetailsValues?.orderId
                  ? orderDetailsValues?.orderId
                  : 'To be selected'}
              </Descriptions.Item>
              <Descriptions.Item label="Note">
                <Tag color={'yellow'}>
                  {orderDetailsValues?.note
                    ? orderDetailsValues.note
                    : 'No Note'}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Tab Id">
                {currentTab?.tabId ? currentTab.tabId : 'To be selected'}
              </Descriptions.Item>
              <Descriptions.Item label="Phone">
                {currentTab?.userPhonenumber
                  ? currentTab.userPhonenumber
                  : '000-000-0000'}
              </Descriptions.Item>

              <Descriptions.Item label="Total Items">
                {orderDetailsValues?.items.length
                  ? orderDetailsValues?.items.length
                  : 'N/A'}
              </Descriptions.Item>


              <Descriptions.Item label="Amount">
                {!isNaN(
                  orderDetailsValues?.totalCart +
                  orderDetailsValues?.serviceCharge +
                  orderDetailsValues?.gst,
                )
                  ? (
                    orderDetailsValues?.totalCart +
                    orderDetailsValues?.serviceCharge +
                    orderDetailsValues?.gst
                    ).toFixed(2)
                  : 'To Be Decided'}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <Tag
                  color={
                    orderDetailsValues?.status === 'PENDING'
                      ? 'red'
                      : orderDetailsValues?.status === 'PREPARING'
                      ? 'gold'
                      : orderDetailsValues?.status === 'COMPLETED'
                      ? 'green'
                      : ''
                  }
                >
                  {orderDetailsValues?.status
                    ? orderDetailsValues?.status
                    : 'To be decided'}                    
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Payment">
                {
                  <Tag
                    color={
                      currentTab?.paid
                        ? Colors.success.description
                        : Colors.failed.description
                    }
                  >
                    {currentTab?.paid ? 'Paid' : 'To be paid'}
                  </Tag>
                }
              </Descriptions.Item>       
            </Descriptions>
            </Panel>
            </Collapse>
            <Descriptions  bordered
              labelStyle={{ backgroundColor: 'white', color: 'grey' }} size="small"
              column={1}>

            <Descriptions.Item label="Assign Waiter">
                <Switch
                  onChange={() => {
                    updateWaiterRequestMutation({
                      waiterRequestId: waiterRequest?.waiterRequestId,
                      token: token,
                      body: {
                        waiterAssigned: true,
                        status: 'CLOSED',
                      },
                    })
                  }}
                  disabled={waiterRequest?.waiterAssigned}
                  checked={waiterRequest?.waiterAssigned}
                  />
                {waiterRequest?.forPayment ? (
                  <Tag color="red" className="ml-5">
                    {' '}
                    Payment
                  </Tag>
                ) : (
                  ''
                  )}
              </Descriptions.Item>
                  </Descriptions>    
          </div>

          <div className="flex-grow">  
            
                  <ItemDetail orderDetailsValues={orderDetailsValues} tableHeight={tableHeight}/>




          </div>

          <div ref={bottomButtonRef} className="flex-none">
            <Row justify="center" gutter={[24, 12]} className="px-5 my-4">
              <Col>
                <OutlinedButton handleClick={handleViewKOT} text="KOT" />
              </Col>
              {getNextStatus(orderDetailsValues?.status) ? (
                <Col>
                  <PrimaryButton
                    disabled={isLoading}
                    text={getPrimaryButtonText(orderDetailsValues?.status)}
                    onClick={handleContinue}
                  />
                </Col>
              ) : null}
              <Col>
                <OutlinedButton
                  handleClick={handleViewInvoice}
                  text="Invoice"
                />
              </Col>
            </Row>
          </div>
        </OrderDetailsCard>
      ) : (
        <div className="px-1 shadow-200" id="invoice">
          <div className="order-details-buttons bg-gray-100 shadow-lg">
      
            {!viewKOT ? (
              <InvoiceDetails
                orderInvoiceData={orderInvoiceData}
                currentTab={currentTab}
                setInvoiceView={setInvoiceView}
                removeItemFromInvoiceMutation={removeItemFromInvoiceMutation}
              />
            ) : (
              <KOTDetails
                orderInvoiceData={orderDetailsValues}
                currentTab={currentTab}
                setInvoiceView={setInvoiceView}
                removeItemFromInvoiceMutation={removeItemFromInvoiceMutation}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}
