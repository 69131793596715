import { Col, Row } from "antd";
import React from "react";
import { useRecoilState } from "recoil";
import searchIcon from "../../assets/images/search-icon.png";
import { searchAtom } from "../../recoils/helperAtoms";

function SearchHeaderComponent(props) {
  const { searchField, title,children } = props;
  const [search, setSearch] = useRecoilState(searchAtom);
  return (
    <Row justify="space-between" className="p-4 border-b ">
      <Col spna={8} className="inline font-bold text-2xl px-10">{title}</Col>
      {searchField && (
        <Col span={children?8:16} className="relative">
          <input
            placeholder="Search"
            className="w-3/5"
            style={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              background: "#eff0f7",
              borderRadius: "10px",
              border: "1px solid #eff0f7",
              paddingLeft: "60px",
              marginRight: "55.83px",
              fontSize: "16px",
              letterSpacing: "0.75px",
              color: "#a0a3bd",
              outline: "none",
            }}
            onChange={(e) => setSearch(e.target.value)}
          />
          <img
            className="absolute bottom-2 left-5"
            src={searchIcon}
            alt="searchIcon"
          />
        </Col>
      )}
      <Col span={searchField?8:16}>
        {children}
      </Col>
    </Row>
  );
}

export default SearchHeaderComponent;
