import Axios from "axios";
import { axiosInitialParams } from "../../utils";

const baseAPIUrl = process.env.REACT_APP_API_SERVER;
const axios = Axios.create({
  baseURL: baseAPIUrl,
  axiosInitialParams,
});

export const getAllReservations = async (token) => {
  token = await token;
  return axios.get("/merchant/reservations", {
    headers: { Authorization: "Bearer " + token },
  });
};
// update reservation
export const updateReservationStatus = async ({
  token,
  reservationId,
  params,
}) => {
  token = await token;
  try {
    return await axios
      .post(`merchant/reservations/updateStatus/${reservationId}`, params, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => response)
      .catch((error) => error.response);
  } catch (error) {
    return error;
  }
};

//create reservation
export const addReservation = async (params, token) => {
  token = await token;
  try {
    return await axios
      .post(`merchant/reservations/createReservation`, params, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => response)
      .catch((error) => error.response);
  } catch (error) {
    return error;
  }
};
