import React from "react";

const Terms = () => {
  return (
    <div className="">
      <iframe
        src="https://docs.google.com/document/d/e/2PACX-1vRXzltRntaPDNE2gBYA1hqDO3KEAlc41A_ZQvjfLeho4o4BjgJQBanXIA6GBRrbu8CkSA9zIiRQwjC6/pub?embedded=true"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        className="m-auto"
        style={{
          width: "900px",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        Loading…
      </iframe>
    </div>
  );
};
export default Terms;
