import { Col, Row } from "antd";
import moment from "moment";
import { React, useState } from "react";
import { useQuery } from "react-query";
import PrimaryButton from "../../../components/CustomButtons/PrimaryButton";
import { useAuth } from "../../../services/auth";
import { getAvailableTable } from "../../../services/tables";
import { TableCard } from "../Tables/tableCard";

export function AssignTableModal(props) {
  const { getIdToken } = useAuth();
  const token = getIdToken();
  const { data } = useQuery(
    "tables",
    () =>
      getAvailableTable({
        reservationTime: props.reservation.reservationAt,
        token,
      }),
    {
      onSuccess: () => {
        console.log("Fetched tables list succesfully");
      },
    }
  );
  const [selectedTable, setSelectedTable] = useState();
  const [disabled, setDisabled] = useState(true);
  const onSelect = (table) => {
    setDisabled(false);
    setSelectedTable(table);
  };
  const onAssign = () => {
    props.onAssignTable(props.reservation, selectedTable);
  };
  return (
    <>
      <div className="overflow-scroll" style={{ height: "60vh" }}>
        <Row>
          {data?.data?.data?.map((table) => (
            <TableCard
              key={table.tableId}
              table={table}
              onSelect={onSelect}
              selectedTable={selectedTable}
            />
          ))}
        </Row>
      </div>
      <Row justify="space-between" align="middle">
        <div className="font-semibold text-base" style={{ color: "orangered" }}>
          Reservation Details
        </div>
        <Col>
          <div className="font-semibold">Phone Number</div>
          <div>{props.reservation.phonenumber}</div>
        </Col>
        <Col>
          <div className="font-semibold">Pax</div>
          <div>{props.reservation.pax}</div>
        </Col>
        <Col>
          <div className="font-semibold">Date</div>
          <div>
            {moment(props.reservation.reservationAt).format("DD MMM YYYY")}
          </div>
        </Col>
        <Col>
          <div className="font-semibold">Time</div>
          <div>{moment(props.reservation.reservationAt).format("hh:mm A")}</div>
        </Col>
        <PrimaryButton text="Assign" disabled={disabled} onClick={onAssign} />
      </Row>
    </>
  );
}
