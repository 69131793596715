import React from "react";

function StatusHeaderContainer(props) {
  return (
    <div className="inline-flex text-center center items-center flex-wrap">
      {props.children}
    </div>
  );
}

export default StatusHeaderContainer;
