import React from "react";
import { QRCodeBody } from "./QRcodeModal";

const PrintAllQRCode = React.forwardRef((props, ref) => {
  const allTables = props.tables;
  return (
    <div ref={ref}>
      {allTables?.map((table, index) => (
        <>
          <div className="page-break" />
          <QRCodeBody key={index} table={table} />
        </>
      ))}
    </div>
  );
});

export default PrintAllQRCode;
