import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { useQuery } from "react-query";
import SearchHeaderComponent from "../../../components/HeaderComponents/search_header_component";
import CommonModal from "../../../components/Modal";
import {
  addAgent,
  deleteAgentById,
  getAllAgents,
  updateAgentById,
} from "../../../services/Agents";
import { useAuth } from "../../../services/auth";
import "../../../assets/styles/global.scss";
import { AgentCard } from "./agentCard";
import { AgentFormModal } from "./agentForm";
import { Row } from "antd";
import RoundedButton from "../../../components/CustomButtons/RoundedButton";

function Agent() {
  const { getIdToken } = useAuth();
  const token = getIdToken();

  const [isAddAgentModal, setIsAddAgentModal] = React.useState(false);

  const { data, refetch } = useQuery("agents", () => getAllAgents(token), {
    onSuccess: () => {
      console.log("Agents List Fetched");
    },
  });

  const deleteAgent = async (agentId) => {
    await deleteAgentById(token, agentId);
    refetch();
  };
  const updateAgent = async (body, id) => {
    await updateAgentById(token, body, id);
    refetch();
  };
  const addNewAgent = async (body) => {
    await addAgent(token, body);
    refetch();
  };

  const handleCancel = () => setIsAddAgentModal(false);
  const showAddAgentModal = () => setIsAddAgentModal(true);

  return (
    <>
      <div id="agent">
        <SearchHeaderComponent title={"Agents"} search={false}>
          <div className="flex">
            <RoundedButton onClick={showAddAgentModal}>
              <PlusOutlined className="pb-1" style={{ color: "#FF5317" }} />
              <div className="inline-flex pl-2 font-bold">Add New Agent</div>
            </RoundedButton>
          </div>
        </SearchHeaderComponent>
        <Row style={{ height: "80vh" }} className="overflow-scroll">
          {data?.data?.data?.map((agent, index) => (
            <AgentCard
              key={index}
              agent={agent}
              deleteAgent={deleteAgent}
              updateAgent={updateAgent}
            />
          ))}
        </Row>
      </div>

      <CommonModal
        title="Add New Agent"
        isModalOpen={isAddAgentModal}
        handleCancel={handleCancel}
        destroyOnClose={true}
      >
        <AgentFormModal onSubmit={addNewAgent} handleCancel={handleCancel} />
      </CommonModal>
    </>
  );
}

export default Agent;
