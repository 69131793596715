import { Table } from "antd";
import clsx from "clsx";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import searchIcon from "../../../../assets/images/search-icon.png";
import CustomDatePicker from "../../../../components/CustomDatePicker/customDatePicker";
import SearchHeaderComponent from "../../../../components/HeaderComponents/search_header_component";
import StatusHeaderContainer from "../../../../components/HeaderComponents/status_header_component";
import { invoiceViewAtom, searchAtom } from "../../../../recoils/helperAtoms";
import {
  currentTakeawayStatusTabAtom,
  takeAwayDetailsByIdAtom,
} from "../../../../recoils/takeaway";
import { useAuth } from "../../../../services/auth";
import { getTakeAwayDetailsById } from "../../../../services/takeaway";
import { filterForTakeAwayTable } from "../../../../utils";

export default function TakeAwayDataTable(props) {
  const { columns, data, tableName, status, isReservation } = props;
  const [current, setCurrent] = useState("");
  const search = useRecoilValue(searchAtom);
  const [currentStatus, setCurrentStatus] = useRecoilState(
    currentTakeawayStatusTabAtom
  );
  const [currentOrder, setCurrentOrder] = useState("");
  const { getIdToken } = useAuth();
  const token = getIdToken();
  const [filterDate, setFilteredDate] = useState();
  const setInvoiceView = useSetRecoilState(invoiceViewAtom);

  const setTakeAwayDetailsById = useSetRecoilState(takeAwayDetailsByIdAtom);
  const {
    mutate: selectedTakeAwayDetailsMutation,
    data: takeAwayDetailsData,
    isSuccess: isFetchedTakeAwayDetailsSuccessfully,
  } = useMutation((data) => getTakeAwayDetailsById(data));

  React.useEffect(() => {
    if (takeAwayDetailsData && isFetchedTakeAwayDetailsSuccessfully) {
      setTakeAwayDetailsById(takeAwayDetailsData?.data?.data);
    }
  }, [
    takeAwayDetailsData,
    isFetchedTakeAwayDetailsSuccessfully,
    setTakeAwayDetailsById,
  ]);

  return (
    <div id="dataTable">
      <SearchHeaderComponent title={tableName} searchField={true} />
      {/* <div className="dataTable-header">
        <div className="dataTable-header-title">{tableName}</div>
        <div className="header-search-input">
          <input
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
          <img src={searchIcon} alt="searchIcon" />
        </div>
      </div> */}
      <StatusHeaderContainer>
        <h3 className="ml-5">Status :</h3>
        <button
          key={"ALL"}
          className={[
            currentStatus === undefined
              ? "ring-0 ring-offset-2 text-red-800 bg-red-200 ring-red-100 "
              : "text-red-400",
            "inline-flex  ml-5 items-center border text-s font-medium rounded px-2 py-0 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-white ",
          ]}
          onClick={() => setCurrentStatus(undefined)}
        >
          {"ALL"}
        </button>

        {status.map((name, index) => {
          return (
            <button
              key={index}
              className={[
                currentStatus === name
                  ? "ring-0 ring-offset-2 text-red-800 bg-red-200 ring-red-100"
                  : "",
                "inline-flex ml-5 my-2 items-center border text-s font-medium rounded px-2 py-0 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-white ",
              ]}
              onClick={() => setCurrentStatus(name)}
            >
              {name}
            </button>
          );
        })}
        <CustomDatePicker value={filterDate} setValue={setFilteredDate} />
      </StatusHeaderContainer>
      <div className="bg-white px-10 pt-10">
        <Table
          columns={columns}
          scroll={{ y: "50vh" }}
          dataSource={
            filterForTakeAwayTable(data, search, currentStatus, filterDate)
            // search && data
            //   ? data?.filter((i) => i.orderId.includes(search))
            //   : currentStatus && data
            //     ? data?.filter((i) => i.status === currentStatus)
            //     : data
          }
          rowClassName={(record, index) =>
            record.orderId === currentOrder ? "bg-gray-100" : "bg-white"
          }
          className="px-2 rounded-sm shadow-lg py-0  "
          // style={{ width: "50vh" }}
          onRow={(record, _rowIndex) => {
            return {
              onClick: () => {
                selectedTakeAwayDetailsMutation({
                  takeAwayId: record.orderId,
                  token: token,
                });
                setCurrentOrder(record.orderId);
                setInvoiceView(false);
              },
            };
          }}
          pagination={{
            defaultPageSize: 10,
          }}
        />
      </div>
    </div>
  );
}
