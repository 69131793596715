import React from "react";
import LocationPicker from "react-location-picker";
import Geocode from "react-geocode";
import PrimaryButton from "../../components/CustomButtons/PrimaryButton";
import { Col, Row, Form, Input } from "antd";
import { TextField } from "@mui/material";

export default function GoogleMapLocationSelector(props) {
  const [address, setAddress] = React.useState();
  const [city, setCity] = React.useState();
  const [neighborhood, setNeighbourhood] = React.useState();
  const [country, setCountry] = React.useState();
  const [pincode, setPincode] = React.useState();
  const [mapForm] = Form.useForm();
  const { setMapModalVisible, form, location, setLocation } = props;

  const handleLocationChange = (event) => {
    console.log(event);
    setLocation({
      latitude: event.position.lat,
      longitude: event.position.lng,
    });
    settingLocation(event.places[0]);
  };

  React.useEffect(() => {
    Geocode.fromLatLng(
      location.latitude,
      location.longitude,
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    ).then(
      (response) => {
        // console.log(response);
        settingLocation(response.results[0]);
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);
  React.useMemo(() => {
    mapForm.setFieldsValue({
      pincode: pincode,
      neighbourhood: neighborhood,
      city: city,
      address: address,
    });
  }, [pincode, address, city, neighborhood]);

  const settingLocation = (value) => {
    setAddress(value.formatted_address);
    for (let i = value.address_components.length - 1; i >= 0; i--) {
      for (let j = 0; j < value.address_components[i].types.length; j++) {
        switch (value.address_components[i].types[j]) {
          case "locality":
            if (
              value.address_components[i].long_name.toLowerCase() ===
              "singapore"
            ) {
              setCity(value.address_components[i].long_name);
            } else setNeighbourhood(value.address_components[i].long_name);
            break;
          case "administrative_area_level_2":
            setCity(value.address_components[i].long_name);
            break;
          case "neighborhood":
            setNeighbourhood(value.address_components[i].long_name);
            break;
          case "country":
            setCountry(value.address_components[i].long_name);
            break;
          case "postal_code":
            setPincode(value.address_components[i].long_name);
            break;
        }
      }
    }
  };

  return (
    <div>
      <Row>
        <Col span={16}>
          <LocationPicker
            containerElement={<div className="h-full" />}
            mapElement={<div className="h-full" />}
            defaultPosition={{
              lat: location.latitude,
              lng: location.longitude,
            }}
            onChange={handleLocationChange}
          />
        </Col>
        <Col span={8} className=" flex flex-col p-5 justify-between">
          <Form form={mapForm} layout="vertical">
            <Form.Item name="address" label="Address">
              <Input.TextArea rows={2} value={address} readOnly />
            </Form.Item>
            <Form.Item name="neighbourhood" label="Neighbourhood">
              <Input readOnly value={neighborhood} />
            </Form.Item>
            <Form.Item name="city" label="City">
              <Input readOnly value={city} />
            </Form.Item>
            <Form.Item name="pincode" label="Pincode">
              <Input readOnly value={pincode} />
            </Form.Item>
          </Form>
          <PrimaryButton
            text="Done"
            onClick={() => {
              form.setFieldsValue({
                pincode: pincode,
                neighbourhood: neighborhood,
                city: city,
                address: address,
              });
              setMapModalVisible(false);
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
