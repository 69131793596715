import React from "react";

const TestimonialComponent = ({ testimonial }) => {
  return (
    <div className="flex flex-col md:flex-row lg:px-24 justify-center items-center w-full">
      <img
        src={testimonial.img}
        className="rounded-full md:self-start md:pb-0 pb-2 inline-block"
        height={184}
        width={184}
      />
      <div className="px-10">
        <div className="text-xl tracking-wider font-semibold">
          {testimonial.name}
        </div>
        <div style={{ color: "#95A4C7" }}>{testimonial.designation}</div>
        <div
          className="text-sm tracking-widest font-medium leading-7 py-5"
          // style={{ width: "700px" }}
        >
          {testimonial.review}
        </div>
        <div className="gradient-reverse bg-clip-text pt-10 font-medium text-lg">
          {testimonial.restaurant}
        </div>
      </div>
    </div>
  );
};

export default TestimonialComponent;
