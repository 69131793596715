import Axios from "axios";
import { axiosInitialParams } from "../../utils";

const baseAPIUrl = process.env.REACT_APP_API_SERVER;
const axios = Axios.create({
  baseURL: baseAPIUrl,
  axiosInitialParams,
});

// return all delivery details
export const getAllDelivery = async (token, pageNo) => {
  // token = await token;
  // return axios.get("/merchant/delivery", {
  //   headers: { Authorization: "Bearer " + token },
  //   params: { pageNo: pageNo, pageSize: 10 }
  // });
};
// returns details of delivery by deliveryId
export const getDeliveryDetailsById = async ({ deliveryId, token }) => {
  token = await token;
  try {
    return await axios.get(`/merchant/delivery/${deliveryId}`, {
      headers: { Authorization: "Bearer " + token },
    });
  } catch (error) {
    return error;
  }
};

// posts delivery detail
export const updateDeliveryStatus = async (id, params) => {
  try {
    return await axios
      .post(`delivery/merchant/updateStatus/${id}`, params)
      .then((response) => response)
      .catch((error) => error.response);
  } catch (error) {
    return error;
  }
};

// Get tab invoice status
export const getInvoiceByOrderId = async ({ token, orderId }) => {
  token = await token;
  try {
    return await axios
      .get(`merchant/delivery/invoice/${orderId}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => response)
      .catch((error) => error.response);
  } catch (error) {
    return error;
  }
};

// Update Take Away status
export const updateDeliveryOrderStatus = async ({ token, id, body }) => {
  token = await token;
  try {
    return await axios
      .put(`merchant/delivery/${id}`, body, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((response) => response)
      .catch((error) => error.response);
  } catch (error) {
    return error;
  }
};
