import React from "react";

function OrderDetailsItem(props) {
  const { label, value } = props;
  return (
    <div className="py-2 pl-6 grid grid-cols-2">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900">{value}</dd>
    </div>
  );
}

export default OrderDetailsItem;
