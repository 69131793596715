import React from "react";
import { useMutation } from "react-query";
import { useAuth } from "../../../../services/auth";
import { deleteCategoryItem } from "../../../../services/inventory";

export default function DeleteCategoryModal(props) {
  const { handleCancel, category, refetchCategory } = props;

  const { getIdToken } = useAuth();
  const token = getIdToken();

  const { mutate: deleteCategoryMutation } = useMutation(
    (data) => deleteCategoryItem(data),
    {
      onSuccess: () => {
        console.log("Category deleted Successfuly");
        refetchCategory();
      },
    }
  );

  function handleDelete(category) {
    const body = {
      params: {
        category: category.name,
      },
      token: token,
    };
    deleteCategoryMutation(body);
  }

  return (
    <>
      <div className="logout-modal-heading">
        Are you sure you want to Delete category ‘{category.name}’ ?
      </div>
      <div className="card-footer">
        <div>
          <button
            className="order-reject-button"
            onClick={() => handleCancel()}
          >
            <span>Cancel</span>
          </button>
        </div>
        <div>
          <button
            className="order-accept-button"
            onClick={() => {
              handleDelete(category);
              handleCancel();
            }}
          >
            <span>Confirm</span>
          </button>
        </div>
      </div>
    </>
  );
}
