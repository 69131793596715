import { Checkbox, Table } from 'antd'
import React from 'react'

const ItemDetail = ({orderDetailsValues, tableHeight}) => {
    const columns = [
        {
          title: 'Item',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Quantity',
          dataIndex: 'nos',
          key: 'nos',
          align: 'center',
        },
        {
          title: 'Available',
          dataIndex: 'nos',
          key: 'nos',
          align: 'center',
          render: () => <Checkbox checked />,
        },
      ]
  return (
    <>
    <Table
              size="small"
              columns={columns}
              className="pl-2 ant-table-thead"
              pagination={{
                position: ['none'],
                pageSize: orderDetailsValues?.items.length,
              }}
              title={() => (
                <span className="text-lg pl-5 font-medium">Items</span>
              )}
              scroll={{ y: `calc(100vh - ${tableHeight + 180}px)` }}
              dataSource={orderDetailsValues?.items}
            />
    </>
  )
}

export default ItemDetail