import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import jwt_decode from "jwt-decode";
import React from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { requestUserRegister, useAuth } from "../../services/auth";
import Logo from "../../assets/images/brand-logo.png";
import Burger from "../../assets/images/munchies-plate.png";
import OwnerDetails from "./Forms/OwnerDetails";
import BusinessDetails from "./Forms/BusinessDetails";
import LocationDetails from "./Forms/LocationDetails";
import { ArrowLeftOutlined, LogoutOutlined } from "@ant-design/icons";
import LogOutModal from "../../components/Navigation/LogOutModal";
import CommonModal from "../../components/Modal";

export default function Register() {
  const history = useHistory();
  const { Option } = Select;
  const { getIdToken, signOut } = useAuth();
  const token = getIdToken();
  const [formNumber, setFormNumber] = React.useState(0);
  const [email, setEmail] = React.useState();
  const [formData, setFormData] = React.useState({});
  const [isLogoutModalVisible, setLogoutModalVisible] = React.useState(false);
  const [location, setLocation] = React.useState({
    latitude: 1.3439,
    longitude: 103.875,
  });
  const {
    mutate: registrationMutation,
    isLoading: isLoadingRegistration,
    data: registeredData,
  } = useMutation((data) => requestUserRegister(data), {
    onSuccess: () => {
      handleLogOut();
      toast.success("Store Registered Succesfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    },
  });
  const [form] = Form.useForm();

  // const prefixSelector = (
  //   <Form.Item name="prefix" noStyle>
  //     <Select style={{ width: 70 }}>
  //       <Option value="+65">+65</Option>
  //       <Option value="+87">+87</Option>
  //       <Option value="+70">+70</Option>
  //       <Option value="+91">+91</Option>
  //     </Select>
  //   </Form.Item>
  // );
  const handleLogOut = async () => {
    await signOut();
    localStorage.removeItem("flunkeyAuthToken");
    localStorage.removeItem("flunkeyMerchantStore");
    history.push("/");
  };

  const onFinish = (values) => {
    switch (formNumber) {
      case 0:
        setFormData({
          ...formData,
          owner: values.owner,
          ownerEmail: values.ownerEmail,
          ownerMobile: values.ownerMobile,
        });
        break;
      case 1:
        setFormData({
          ...formData,
          restaurantName: values.restaurantName,
          description: values.description,
          isLive: false,
          allowDelivery: values.allowDelivery ?? false,
          allowReservations: values.allowReservations ?? false,
          allowTakeaway: values.allowTakeaway ?? false,
          imageUrl: "https://www.flunkey.app/logo192.png",
          avgTime: values.avgTime,
        });
        break;
      case 2:
        const body = {
          token: token,
          params: {
            ...formData,
            address: values.address,
            neighbourhood: values.neighbourhood,
            city: values.city,
            pincode: values.pincode,
            location: location,
          },
        };
        // console.log(body);
        registrationMutation(body);
        break;
      default:
        break;
    }

    setFormNumber((prev) => ++prev);
  };
  const getCurrentEmail = async () => {
    const tokenReal = await token;
    const decodedJWT = jwt_decode(tokenReal);
    const email = decodedJWT.email;
    // console.log(email);
    setEmail(email);
  };

  React.useEffect(() => {
    getCurrentEmail().then(() => {
      form.setFieldsValue({
        ownerEmail: email,
      });
    });
  }, [token]);

  React.useEffect(() => {
    let cancel = false;
    navigator.geolocation.getCurrentPosition((position) => {
      if (cancel) return;
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
      return () => {
        cancel = true;
      };
    });
  }, [registeredData, history]);

  return (
    <>
      <button
        onClick={() => {
          setLogoutModalVisible(true);
        }}
        className="text-white absolute z-10 right-0 p-4 text-md gradient-reverse"
      >
        <LogoutOutlined style={{ fontSize: 24 }} />
        &nbsp; Logout
      </button>
      <Row>
        <Col
          span={8}
          className="h-screen p-10"
          style={{ background: "#F4F0E9" }}
        >
          <img src={Logo} alt="Logo" />
          <img
            src={Burger}
            alt="burger"
            height={300}
            width={300}
            className="py-10"
          />
          <div className="text-4xl font-semibold" style={{ lineHeight: 1.3 }}>
            More Orders,
            <br /> More Customers,
            <br /> More money with
            <br /> <span style={{ color: "#ff5317" }}>Flunkey</span>
          </div>
          <div className="pt-5 text-base">
            Manage your food outlt’s complete
            <br /> digital experience
          </div>
        </Col>
        <Col span={16} className="bg-gray-50">
          <Row justify="center" align="middle" className="h-full">
            <Col className="rounded-lg ring-1 ring-gray-200 p-14 bg-white ">
              {formNumber !== 0 && (
                <button
                  onClick={() => {
                    setFormNumber((prev) => --prev);
                  }}
                >
                  <ArrowLeftOutlined
                    style={{ color: "#ff5317", fontSize: "16px" }}
                  />
                </button>
              )}
              <Form
                form={form}
                layout="vertical"
                className="py-5"
                onFinish={onFinish}
              >
                {formNumber == 0 ? (
                  <OwnerDetails />
                ) : formNumber == 1 ? (
                  <BusinessDetails />
                ) : (
                  <LocationDetails
                    location={location}
                    setLocation={setLocation}
                    isLoadingRegistration={isLoadingRegistration}
                    form={form}
                  />
                )}
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>

      <CommonModal
        isModalOpen={isLogoutModalVisible}
        handleCancel={() => setLogoutModalVisible(false)}
        modalWidth={320}
      >
        <LogOutModal
          setLogoutModalVisible={setLogoutModalVisible}
          handleMenuClick={handleLogOut}
        />
      </CommonModal>
    </>
  );
}
