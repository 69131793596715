import moment from "moment";
import React from "react";
import { calcTimeAgo } from "../../../utils/index";
import DeliveryDataTable from "./DeliveryDataTable";
import { Tag } from "antd";

export default function DeliveryTable() {
  const columns = [
    {
      title: "Order ID",
      key: "orderId",
      dataIndex: "deliveryId",
      width: 140,
      fixed: "left",
    },
    {
      title: "Ordered at",
      key: "createdAt",
      width: 200,
      render: (order) => {
        return (
          <>
            {moment(order?.createdAt._seconds * 1000).format("DD MMM YY ") +
              " - " +
              calcTimeAgo(order?.createdAt._seconds * 1000)}
          </>
        );
      },
    },
    // {
    //   title: "Type",
    //   width: 80,
    //   key: "type",
    //   render: (order) => (
    //     <Tag color={order?.serveOnSite ? "orange" : "blue"}>
    //       {order?.serveOnSite ? "SERVE" : "PACK"}
    //     </Tag>
    //   ),
    // },
    {
      title: "Phone No",
      key: "phoneNo",
      dataIndex: "userPhonenumber",
      width: 150,
    },
    {
      title: "Items",
      key: "items",
      render: (order) => order?.items?.length,
      width: 80,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 120,
      render: (status) => (
        <Tag
          color={
            status === "PENDING"
              ? "red"
              : status === "PREPARING"
              ? "gold"
              : status === "PREPARED"
              ? "magenta"
              : status === "OUT_FOR_DELIVERY"
              ? "blue"
              : "green"
          }
        >
          <span className="">
            {status === "OUT_FOR_DELIVERY" ? "OFD" : status}
          </span>
        </Tag>
      ),
    },
    // {
    //   title: "Delivery State",
    //   key: "state",
    //   render: (order) => order?.deliveryDetails?.status?.replaceAll("_", " "),
    //   width: 180,
    // },
  ];

  const orderStatus = ["PENDING", "PREPARING", "PREPARED", "OUT_FOR_DELIVERY","COMPLETED"];

  return (
    <>
      <DeliveryDataTable
        columns={columns}
        tableName="Delivery"
        status={orderStatus}
        isReservation={false}
      />
    </>
  );
}
