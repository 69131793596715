import Axios from "axios";
import { axiosInitialParams } from "../../utils";

const baseAPIUrl = process.env.REACT_APP_API_SERVER;
const axios = Axios.create({
  baseURL: baseAPIUrl,
  axiosInitialParams,
});

// return all tables
export const getAllTables = async (token) => {
  token = await token;
  return axios.get("/merchant/tables", {
    headers: { Authorization: "Bearer " + token },
  });
};
// return avaliable tables for reservations
export const getAvailableTable = async ({ reservationTime, token }) => {
  token = await token;
  return axios.get(
    `/merchant/getAvailableTables?reservationTime=${reservationTime}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
};

// Use for Add new table
export const addTable = async (body, token) => {
  token = await token;
  return await axios
    .post("/merchant/tables", body, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else {
        console.log("Error", error.message);
      }
    });
};

// Use for edit table
export const editTable = async (tableId, params, token) => {
  token = await token;
  return await axios
    .put(`/merchant/tables/${tableId}`, params, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else {
        console.log("Error", error.message);
      }
    });
};

// Use for edit table
export const deleteTableById = async (tableId, token) => {
  token = await token;
  return await axios
    .delete(`/merchant/tables/${tableId}`, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else {
        console.log("Error", error.message);
      }
    });
};
