import { Popconfirm, Row, Tooltip } from 'antd'
import React from 'react'
import CommonModal from '../../../components/Modal'
import { TableFormModal } from './tableForm'
import clsx from 'clsx'
import { DeleteFilled } from '@ant-design/icons'
import QRcodeModal from './QRcodeModal'

export function TableCard(props) {
  const { table, deleteTable, updateTable, onSelect, selectedTable } = props
  const [isEditTableModal, setIsEditTableModal] = React.useState(false)
  const [isQRModal, setIsQRModal] = React.useState(false)
  const handleDelete = () => {
    deleteTable(table.tableId)
  }
  const handleEdit = () => setIsEditTableModal(true)
  const showQR = () => setIsQRModal(true)
  const handleCancel = () => {
    setIsEditTableModal(false)
    setIsQRModal(false)
  }
  const handleSelect = () => {
    if (onSelect !== undefined) onSelect(table)
  }
  return (
    <>
      <div
        className={clsx(
          {
            'ring-2 ring-yellow-500': selectedTable === table,
          },
          'shadow-md m-5 rounded-lg w-80 h-56',
        )}
        onClick={handleSelect}
      >
        <div className="pb-2">
          <div className="grid grid-cols-3 gap-4 p-2 border">
            <div className="font-bold col-span-1">Table Name</div>
            <Row
              className="font-bold col-span-2"
              style={{ color: 'orangered' }}
              justify="space-between"
              align="top"
            >
              {table?.customTableId.length > 23 ? (
                <Tooltip
                  title={table?.customTableId}
                  arrow={false}
                  color="#ff5317"
                >
                  <span>{table?.customTableId.slice(0, 23)}</span>
                </Tooltip>
              ) : (
                <span>{table?.customTableId}</span>
              )}

              {deleteTable !== undefined && (
                <Popconfirm
                  title="Are you sure you want to delete this Table"
                  okText="Yes"
                  onConfirm={handleDelete}
                >
                  <button>
                    <DeleteFilled />
                  </button>
                </Popconfirm>
              )}
            </Row>
          </div>
          <BoxFields field="Type" value={table.type} />
          <BoxFields field="Seats" value={table.noOfSeats} />
          <BoxFields field="Status" value={table.status} />
          <BoxFields field="Description" value={table.description} />
          {/* <BoxFields field="Description" value={table.tableExternalDetails} /> */}
        </div>
        {deleteTable !== undefined && (
          <div className="grid grid-cols-2">
            <button
              className="border p-1 font-bold"
              style={{ color: 'orangeRed' }}
              onClick={handleEdit}
            >
              Edit
            </button>
            <button
              className="border p-1 font-bold"
              style={{ color: 'orangeRed' }}
              onClick={showQR}
            >
              Show QR Code
            </button>
          </div>
        )}
      </div>
      <CommonModal
        title="Edit Table"
        isModalOpen={isEditTableModal}
        handleCancel={handleCancel}
        destroyOnClose={true}
      >
        <TableFormModal
          table={table}
          onSubmit={updateTable}
          handleCancel={handleCancel}
        />
      </CommonModal>

      <CommonModal
        title="QR Code"
        isModalOpen={isQRModal}
        handleCancel={handleCancel}
        destroyOnClose={true}
      >
        <QRcodeModal table={table} handleCancel={handleCancel} />
      </CommonModal>
    </>
  )
}

export function BoxFields(props) {
  const { field, value } = props

  return (
    <div className="grid grid-cols-3 p-2">
      <div className="font-bold col-span-1">{field}</div>

      {value.length > 25 ? (
        <Tooltip title={value} color="#ff5317" arrow={false}>
          <div className="font-light col-span-2">{value.slice(0, 25)}</div>
        </Tooltip>
      ) : (
        <div className="font-light col-span-2">{value}</div>
      )}
    </div>
  )
}
