import MainLayout from "./layout/MainLayout";
import AdminLayout from "./layout/AdminLayout";

import { withAuth } from "./hoc";
import FlunkeyLanding from "./pages/Landing";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Blog from "./pages/Blog";
import NoStore from "./pages/NoStore";
import Register from "./pages/Register";
import Overview from "./pages/Admin/Overview";
import DineIn from "./pages/Admin/DineIn";
import TakeAway from "./pages/Admin/TakeAway";
import Delivery from "./pages/Admin/Delivery";
import Reservations from "./pages/Admin/Reservations";
import Inventory from "./pages/Admin/Inventory";
import Tables from "./pages/Admin/Tables";
import RestaurantProfile from "./pages/Admin/RestaurantProfile";
import NotFoundPage from "./pages/NotFoundPage";
import Agent from "./pages/Admin/Agent";
import Terms from "./pages/Terms&Service/terms&service";
import PrivacyPolicy from "./pages/Terms&Service/privacyPolicy";

/**
 * @routes {array} of routes
 * @returns two type routes:
 * 1. Account: All routes use for auth
 * 2. Main: All routes use for internal routing (Private routes)
 */

const routes = [
  {
    path: "/admin",
    component: withAuth(AdminLayout),
    routes: [
      {
        path: "/admin",
        exact: true,
        component: Overview,
        key: "admin-overview",
      },
      {
        path: "/admin/overview",
        exact: true,
        component: Overview,
        key: "overview",
      },
      {
        path: "/admin/dine-in",
        exact: true,
        component: DineIn,
        key: "admin-dine-in",
      },
      {
        path: "/admin/take-away",
        exact: true,
        component: TakeAway,
        key: "admin-take-away",
      },
      {
        path: "/admin/delivery",
        exact: true,
        component: Delivery,
        key: "admin-take-away",
      },
      {
        path: "/admin/reservations",
        exact: true,
        component: Reservations,
        key: "admin-reservations",
      },
      {
        path: "/admin/inventory",
        exact: true,
        component: Inventory,
        key: "admin-inventory",
      },
      {
        path: "/admin/tables",
        exact: true,
        component: Tables,
        key: "admin-tables",
      },
      {
        path: "/admin/agent",
        exact: true,
        component: Agent,
        key: "admin-agent",
      },
      {
        path: "/admin/profile",
        exact: true,
        component: RestaurantProfile,
        key: "admin-restaurant-profile",
      },
      {
        path: "*",
        component: NotFoundPage,
        key: "not-found",
      },
    ],
  },
  {
    path: "/register",
    exact: true,
    component: Register,
    key: "register",
  },
  {
    path: "/",
    component: MainLayout,
    routes: [
      {
        path: "/",
        exact: true,
        component: FlunkeyLanding,
        key: "homepage",
      },
      // {
      //   path: "/register",
      //   exact: true,
      //   component: Register,
      //   key: "register",
      // },
      // {
      //   path: "/nostore",
      //   exact: true,
      //   component: withAuth(NoStore),
      //   key: "no-store",
      // },
      {
        path: "/contact-us",
        exact: true,
        component: ContactUs,
        key: "contact-us",
      },
      {
        path: "/about-us",
        exact: true,
        component: AboutUs,
        key: "about-us",
      },
      {
        path: "/blog",
        exact: true,
        component: Blog,
        key: "blog",
      },
      {
        path: "/terms-and-service",
        exact: true,
        component: Terms,
        key: "terms-and-service",
      },
      {
        path: "/privacy-policy",
        exact: true,
        component: PrivacyPolicy,
        key: "privacy-policy",
      },
      {
        path: "*",
        component: NotFoundPage,
        key: "not-found",
      },
    ],
  },
];

/**
 * Generate Route
 * @param {string} key route key
 * @param {array} routes routes array
 * @returns {string} url
 */

const generateRoute = (key, mainRoutes = routes) => {
  let url = false;
  mainRoutes.some((i) => {
    if (i.key === key && !url) {
      url = i.path;
    } else if (i.routes) {
      url = generateRoute(key, i.routes);
    }
    return url;
  });
  return url;
};

export { routes, generateRoute };
