import React, { useState } from "react";
import { Col, DatePicker, Form, Input, Row, Select, TimePicker } from "antd";
import PrimaryButton from "../../../components/CustomButtons/PrimaryButton";

export function AddReservationFormModal(props) {
  const { onSubmit, handleCancel } = props;
  const { Option } = Select;
  const [prefix, setPrefix] = useState("+65");
  const [showOtherField, setShowOtherField] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    values.phonenumber = prefix + values.phonenumber;
    values.reservationAt = values.reservationAt.valueOf();
    onSubmit(values);
    form.resetFields();
    handleCancel();
  };

  return (
    <div>
      <Form
        form={form}
        name="add_reservation"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item label="Pax" rules={[{ required: true }]} name="pax">
          <Input placeholder="2" />
        </Form.Item>
        <Input.Group size="large">
          <Form.Item
            label="Phone (customer)"
            rules={[{ required: true }]}
            name="phonenumber"
          >
            <Row>
              <Col span={4}>
                <Input
                  value={prefix}
                  onChange={(e) => {
                    setPrefix(e.target.value);
                  }}
                />
              </Col>
              <Col span={20}>
                <Input placeholder="Phone Number" />
              </Col>
            </Row>
          </Form.Item>
        </Input.Group>
        <Form.Item
          label="Reservation At"
          rules={[{ required: true }]}
          name="reservationAt"
        >
          <DatePicker
            inputReadOnly
            placeholder="Reservation At"
            showTime
            format={"DD MMM yy @ hh:mm a"}
            className="w-full"
          />
        </Form.Item>
        <Form.Item label="Source" rules={[{ required: true }]} name="source">
          <Select
            onChange={(e) => {
              setShowOtherField(e === "Others");
            }}
          >
            <Option value="Whatsapp">Whatsapp</Option>
            <Option value="Facebook">Facebook</Option>
            <Option value="Others">Others</Option>
          </Select>
        </Form.Item>
        {showOtherField && (
          <Form.Item label="Other Source" name="otherSource">
            <Input placeholder="Please specify other source" />
          </Form.Item>
        )}
        <PrimaryButton text="Submit" type="submit" />
      </Form>
    </div>
  );
}
