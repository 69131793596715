import { Popconfirm } from "antd";
import React from "react";
import CommonModal from "../../../components/Modal";
import { AgentFormModal } from "./agentForm";
import clsx from "clsx";

export function AgentCard(props) {
  const { agent, deleteAgent, updateAgent, onSelect, selectedAgent } = props;
  const [isEditAgentModal, setIsEditAgentModal] = React.useState(false);
  const handleDelete = () => {
    console.log(agent);
    deleteAgent(agent.agentId);
  };
  const handleEdit = () => setIsEditAgentModal(true);
  const handleCancel = () => setIsEditAgentModal(false);
  const handleSelect = () => {
    if (onSelect !== undefined) onSelect(agent);
  };

  return (
    <>
      <div
        className={clsx(
          {
            "ring-2 ring-yellow-500": selectedAgent === agent,
          },
          "shadow-md m-5 rounded-lg w-80 h-56"
        )}
        onClick={handleSelect}
      >
        <div className="pb-2">
          <div className="grid grid-cols-3 gap-4 p-2 border">
            <div className="font-bold col-span-1">AgentId</div>
            <div
              className="font-bold col-span-2"
              style={{ color: "orangered" }}
            >
              {agent.agentId}
            </div>
          </div>
          <BoxFields field="Name" value={agent.agentName} />
          <BoxFields field="Email" value={agent.agentEmail} />
          <BoxFields field="Phone" value={agent.agentPhone} />
          <BoxFields field="Status" value={agent.agentStatus} />
          {/* <BoxFields field="Description" value={agent.agentExternalDetails} /> */}
        </div>
        {deleteAgent !== undefined && (
          <div className="grid grid-cols-2">
            <button
              className="border p-1 font-bold"
              style={{ color: "orangeRed" }}
              onClick={handleEdit}
            >
              Edit
            </button>
            <Popconfirm
              title="Are you sure you want to delete this Agent"
              okText="Yes"
              onConfirm={handleDelete}
            >
              <button
                className="border p-1 font-bold"
                style={{ color: "orangeRed" }}
              >
                Delete
              </button>
            </Popconfirm>
          </div>
        )}
      </div>
      <CommonModal
        title="Edit Agent"
        isModalOpen={isEditAgentModal}
        handleCancel={handleCancel}
        destroyOnClose={true}
      >
        <AgentFormModal
          agent={agent}
          onSubmit={updateAgent}
          handleCancel={handleCancel}
        />
      </CommonModal>
    </>
  );
}

export function BoxFields(props) {
  const { field, value } = props;
  return (
    <div className="grid grid-cols-3 p-2">
      <div className="font-bold col-span-1">{field}</div>
      <div className="font-light col-span-2">{value}</div>
    </div>
  );
}
